import React, { useState } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import Footer from "../../commons/components/footer/footer";
import Header from "../../commons/components/header/header";
import PrivacyPolicy from "./privacy-policy";
import Terms from "./terms";
import "./privacy.scss";

const Privacy = (props) => {
	const search = props.location.search;
	const params = new URLSearchParams(search);
	const tab = params.get('tab') !== null ? params.get('tab') : 'terms';
	const [key, setKey] = useState(tab);
	return (
		<Container className="container-privacy-bg" fluid >
			<div className="container-privacy">
				<Tabs
					id="termsandprivacy"
					activeKey={key}
					onSelect={(k) => setKey(k)}
					className="privacy-tab"
				>
					<Tab
						eventKey="terms"
						title="Terms &amp; Conditions"
					>
						<Terms />
					</Tab>
					<Tab
						eventKey="privacy"
						title="Privacy Policy"
					>
						<PrivacyPolicy />
					</Tab>
				</Tabs>
				<br />
			</div>
		</Container>
	)
}

export default withRouter(Privacy);