import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import Footer from "../../../commons/components/footer/footer";
import Header from "../../../commons/components/header/header";
import Landing from "./landing.jsx";
import ActNow from "./actnow";
import Join from "./join";

class ClassAction extends Component {

	render() {
		return (
			<Container className="container-classaction-main" style={{overflow:"hidden"}} fluid>
				<Header white={true}/>
					<Landing />
					<ActNow />
					<Join />
				<Footer />
			</Container>
		);
	}
}

export default withRouter(ClassAction);
