import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./thankyou.scss";
import { FacebookShareButton, TwitterShareButton } from "react-share";


class Social extends Component {
	constructor() {
		super();
		this.bluetile1 = "images/square-1.png";
		this.state = {
			socialBG: this.bluetile1,
			blueVisible: 'visible'
		}
		this.setBlueBG = this.setBlueBG.bind(this);

	}
	
	setBlueBG() {
		this.setState({
			socialBG: this.bluetile1,
			greenVisible: 'hidden',
			orangeVisible: 'hidden',
			blueVisible: 'visible'
		});
	}

	render() {
		return (
			<Container className="container-social" fluid>
				<div className="mydata-rectangle" style={{ backgroundImage: `url(${process.env.REACT_APP_WEB_URL}${this.state.socialBG})` }} >  </div>
				<div className="oval-div" >
					{/* <div className="green-big-oval" onClick={this.setGreenBG}><div className="small-oval-1" style={{visibility:this.state.greenVisible}}></div></div> */}
					<div className="blue-oval" onClick={this.setBlueBG}><div className="small-oval-2" style={{visibility:this.state.blueVisible}}></div></div>
					{/* <div className="blue-oval" onClick={this.setBlueBG}><div className="small-oval-3" style={{visibility:this.state.blueVisible}}></div></div> */}
				</div>
				<div className="share-text-div">
					SHARE:
			</div>
				<div className="social-div" >
					<FacebookShareButton className="black-rectangle" url={`https://datadividendproject.com`}><div className="fb-image"></div></FacebookShareButton>
					<TwitterShareButton className="black-rectangle" url={`https://datadividendproject.com`}><div className="twitter-image"></div></TwitterShareButton>
					<div className="black-rectangle"><a href="http://instagram.com/ddpforall" target="_blank" rel="noopener noreferrer"><div className="instagram-image"></div></a></div>
				</div>
			</Container>
		);
	}

}

export default Social;