import React from "react";
import { Container, Accordion, Card, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import "../../aboutus/aboutus.scss";
import Footer from "../../../commons/components/footer/footer";

const Faq = (props) => {
	return (
		<Container className="clafaq-container" fluid>
			<div className="cla-faq-heading">FAQ</div>
			<p />
			{props.faq && (
				<Accordion className="accordion accordion-flush">
					{
						props.faq.questions.map((question, index) => (
							<React.Fragment key={`${index}-question`}>
								<Card key={`${index}-question`} className="card-container">
									<Card.Header className="card-title">
										<Accordion.Toggle as={Card.Header} variant="link" eventKey={question.question}>
											{question.question}
										</Accordion.Toggle>
									</Card.Header>
									<Accordion.Collapse eventKey={question.question}>
										<Card.Body className="faq-card-text">
											<ReactMarkdown className="faq-card-text	" source={question.answer} linkTarget={'_blank'} />
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<br />
							</React.Fragment>
						))
					}
				</Accordion>
			)}
		</Container>
	)
}

export default Faq;