import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import Footer from "../../commons/components/footer/footer";
import Header from "../../commons/components/header/header";
import ContentService from "../../commons/services/content-service";
import "./manifesto.scss";

const Manifesto = (props) => {
	const [pageContent, setPageContent] = useState(null);

	useEffect(() => {
		async function fetchData() {
			console.log('skjfghadsfljkghdfslgjkhdsfglkjhsd')
			const contentService = new ContentService();
			const manifestoData = await contentService.getPageContent('manifesto');
			setPageContent(manifestoData);
		}
		fetchData();
	}, []); // Or [] if effect doesn't need props or state


	return (
		<Container className="container-manifesto-bg" fluid>
			<Header textBlack={true}/>
			<br />
			<br />
			<br />
			<br />	
			<div className="container-manifesto">
				{pageContent && (
					<div className="card-body">
						<ReactMarkdown className="manifesto-markdown-container" source={pageContent.html} escapeHtml={false} linkTarget={'_blank'} />
					</div>
				)}
			</div>
			<br />
			<br />
			<br />
			<Footer />
		</Container>
	)
}

export default Manifesto;