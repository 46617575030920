import React, { Component } from 'react';
import { Container, Button, Table, Spinner } from 'react-bootstrap';
import { Redirect, withRouter } from "react-router-dom";
import Footer from "../../../commons/components/footer/footer";
import Header from "../../../commons/components/header/header";
import AccountNav from "../../../commons/components/header/account-nav";
import AccountService from "../../../commons/services/account-service";
import Analytics from "../../../commons/analytics";
import * as Utils from "../../../commons/utils";
import UserModel from "../../../commons/model/user";
import "./user-details.scss";
import { TermsModal } from "./terms-modal";

class UserDetails extends Component {
	constructor(props) {
		super(props);
		const user = new UserModel({});
		this.state = {
			completed: false,
			error: null,
			valid: false,
			user,
			processing: false,
			showTermsModal: false,
		}
		this.analytics = new Analytics();
	}

	handleModalClose = async () => {
		const accountService = new AccountService();
		const user = await accountService.getUser(Utils.getUserId());
		if (user.error || user.errors) {
			this.setState({ error: user.error || user.errors });
			return;
		}
		this.setState({
			user,
			showTermsModal: user.termsAcceptedAt !== '' ? false : true,
		})
	}

	saveAndContinue = async (e) => {
		e.preventDefault()
		console.log('saveAndContinue:', this.state.user);
		const errors = Utils.validateUserData(this.state.user);
		this.setState({ processing: true });
		if (errors.length > 0) {
			const errorText = errors.reduce((errorString, error) => ({ error: errorString.error + "\n" + error.error }));
			this.setState({ error: errorText.error, processing: false, });
			return;
		}
		try {
			console.log('about to update user', this.state.user);
			const accountService = new AccountService();
			const user = await accountService.updateUser(this.state.user.getDataForUpdate());

			if (user.error || user.errors) {
				this.setState({ error: user.error || user.errors, processing: false, });
				return;
			}
			this.analytics.trackEvent({
				pageName: 'AccountDetails',
				cta: 'Save',
				actionName: 'clicked'
			});
			console.log('saved user:', user);
			this.setState({ completed: true, processing: false, error: 'Saved successfully!' });
		} catch (err) {
			console.log(err);
			this.setState({ error: err.message, processing: false, });
		}
	}

	handleChange = (event) => {
		const user = this.state.user;
		user[event.target.id] = event.target.value;
		this.setState({ user });
	};

	async componentDidMount() {
		const accountService = new AccountService();
		const user = await accountService.getUser(Utils.getUserId());
		if (user.error || user.errors) {
			this.setState({ error: user.error || user.errors });
			return;
		}
		this.setState({
			user,
			showTermsModal: user.termsAcceptedAt !== '' ? false : true,
		})
	}

	requestResend = async (e) => {
		try {

			if (this.state.user.email) {
				const accountService = new AccountService();
				await accountService.resendEmailVerification(this.state.user.email);
				this.setState({ error: 'Verification email sent.' });
				this.analytics.trackEvent({
					pageName: 'AccountDetails',
					cta: 'email',
					actionName: 'resend'
				});
			}
		} catch (err) {
			this.setState({
				error: 'Request to resend email verfication failed.'
			});
		}
		e.preventDefault();
	};

	requestResendContract = async (e) => {
		try {

			if (this.state.user.trackingNumber) {
				const accountService = new AccountService();
				await accountService.resendProxy();
				this.setState({ error: 'A copy of your Authorization/Proxy Agreement sent to your primary email.' });
				this.analytics.trackEvent({
					pageName: 'AccountDetails',
					cta: 'email',
					actionName: 'resend'
				});
			}
		} catch (err) {
			this.setState({
				error: 'Request to resend email verfication failed.'
			});
		}
		e.preventDefault();
	};


	render() {
		if (!Utils.getAuthToken() || this.state.error === 'Invalid token.') {
			return (<Redirect to="/signin" />);
		}

		const {
			user: {
				firstName, lastName, email, phone, street, city, state, zipCode, yob, trackingNumber, emailVerified, proxyAuthorization, referralCode, referralCount
			},
			processing,
			showTermsModal
		} = this.state;
		return (
			<React.Fragment>
				<Container className="container-userdetails-bg" fluid>
					<Header textBlack={true} />
					<br />
					<br />
					<br />
					<br />
					<br />
					<div className="container-userdetails">
					<AccountNav />
					<TermsModal
						show={showTermsModal}
						onHide={this.handleModalClose}
					/>
					<Table className="user-details-table" responsive>
						<thead>
							<tr>
								<th className="intro-heading">Your Account Information
								</th>
							</tr>
						</thead>
						<tbody>
							{
								trackingNumber && (
									<React.Fragment>
										<tr className="intro-row">
											<td className="intro-col">
												<div className="col-text-bold">Tracking Number</div>
												<div className="form-check-blue-label">You cannot edit your DDP Tracking Number</div>
												<input
													type="text"
													className="col-text col-text-maxwidth"
													id="trackingNumber"
													disabled
													readOnly
													value={trackingNumber}></input>
											</td>
										</tr>
										<tr className="intro-row">
											<td className="intro-col">
												<Button
													className="resend-button"
													onClick={this.requestResendContract}
												>
													Email copy of Authorization/Proxy Agreement
											</Button>
											</td>
										</tr>
										{/* <tr className="intro-row">
										<td className="intro-col">
											<div className="col-text-bold">Referral Link</div>
											<div className="form-check-blue-label">You cannot edit your DDP Referral Link</div>
											<a
												className="col-text col-text-maxwidth"
												id="referralCode"
												href={`${process.env.REACT_APP_WEB_URL}signup?ref=${referralCode}`}>{`${process.env.REACT_APP_WEB_URL}signup?ref=${referralCode}`}</a>
										</td>
									</tr>
									<tr className="intro-row">
										<td className="intro-col">
											<div className="col-text-bold">Total Referrals to date</div>
											<div className="form-check-blue-label">You cannot edit your total Referrals</div>
											<input
												type="text"
												className="col-text col-text-maxwidth"
												id="referralCount"
												disabled
												readOnly
												value={referralCount}></input>
										</td>
									</tr> */}
									</React.Fragment>
								)
							}
							<tr className="intro-row">
								<td className="intro-col">
									<div className="col-text-bold">Mobile Phone</div>
									<div className="form-check-blue-label">At this time, you cannot change your primary Mobile Phone number that you used to join DDP.</div>
									<input
										type="tel"
										maxLength="10"
										className="col-text col-text-maxwidth"
										onChange={(e) => this.handleChange(e)}
										id="phone" value={phone}
										placeholder="10-Digit Mobile Phone Number"
										pattern="\d{10}"
										readOnly
										disabled
									/>
								</td>
							</tr>
							<tr className="intro-row">
								<td className="intro-col">
									<div className="col-text-bold">Email</div>
									<input type="text" className="col-text col-text-maxwidth" onChange={(e) => this.handleChange(e)} id="email" value={email}></input>
								</td>
							</tr>
							{
								emailVerified === 'N' && (
									<tr className="intro-row">
										<td className="intro-col">
											<Button
												className="resend-button"
												onClick={this.requestResend}
											>
												Resend Email Verification
										</Button>
										</td>
									</tr>
								)
							}
							<tr className="intro-row">
								<td className="intro-col">
									<div className="col-text-bold">First Name</div>
									<input
										type="text"
										className="col-text uppercase-text col-text-maxwidth"
										autoFocus
										onChange={(e) => this.handleChange(e)}
										id="firstName"
										value={firstName}
									/>
								</td>
							</tr>
							<tr className="intro-row">
								<td className="intro-col">
									<div className="col-text-bold">Last Name</div>
									<input type="text" className="col-text uppercase-text col-text-maxwidth" onChange={(e) => this.handleChange(e)} id="lastName" value={lastName}></input>
								</td>
							</tr>
							<tr className="intro-row">
								<td className="intro-col">
									<div className="col-text-bold">Street</div>
									<input type="text" className="col-text uppercase-text col-text-maxwidth" onChange={(e) => this.handleChange(e)} id="street" value={street}></input>
								</td>
							</tr>
							<tr className="intro-row">
								<td className="intro-col">
									<div className="col-text-bold">City</div>
									<input type="text" className="col-text uppercase-text col-text-maxwidth" onChange={(e) => this.handleChange(e)} id="city" value={city}></input>
								</td>
							</tr>
							<tr className="intro-row">
								<td className="intro-col">
									<div className="col-text-bold">State</div>
									<input type="text" className="col-text uppercase-text col-text-maxwidth" onChange={(e) => this.handleChange(e)} id="state" value={state}></input>
								</td>
							</tr>
							<tr className="intro-row">
								<td className="intro-col">
									<div className="col-text-bold">Zip Code</div>
									<input
										type="text"
										className="col-text col-text-maxwidth"
										placeholder="Enter Zip Code"
										onChange={(e) => this.handleChange(e)}
										maxLength="5"
										required
										id="zipCode"
										value={zipCode}
										pattern="[0-9][0-9][0-9][0-9][0-9]"
									/>
								</td>
							</tr>
							<tr className="intro-row">
								<td className="intro-col">
									<div className="col-text-bold">Date of Birth (MM/DD/YYYY)</div>
									<input
										type="text"
										className="col-text col-text-maxwidth"
										maxLength="10" onChange={(e) => this.handleChange(e)}
										id="yob"
										value={yob}
										placeholder="MM/DD/YYYY"
										pattern="[0-1][0-2][/-][0-3][0-9][/-][1-2]\d{3}"
									/>
								</td>
							</tr>
							{this.state.error && (
								<tr className="intro-row">
									<td className="intro-col">
										<span className="error-text">{this.state.error}</span>
									</td>
								</tr>
							)}
							<tr>
								<td className="intro-col">
									{!processing &&
										<Button
											className="next-button"
											onClick={this.saveAndContinue}
										>
											SAVE{" "}
										</Button>
									}
									{processing &&
										<Spinner animation="grow" variant="success" />
									}
									{
										!proxyAuthorization && (
											<Button className="next-button" onClick={this.back}>
												<a href="/signature" className="no-underline col-text-bold">
													AUTHORIZE PROXY
											</a>
											</Button>
										)
									}
								</td>
							</tr>
						</tbody>
					</Table>
					</div>
				</Container>
				<Footer />
			</React.Fragment>
		);
	}
}

export default withRouter(UserDetails);