import * as Utils from "../utils";
import UserModel from "../model/user";
import makeRest from "./rest.js";

export default class DdpService {
  async createUser(args) {
		try {
			const user = {
				first_name: args.firstName.toUpperCase(),
				last_name: args.lastName.toUpperCase(),
				email: args.email,
				phone: args.phone,
				street1: args.street.toUpperCase(),
				city: args.city.toUpperCase(),
				state: args.state.toUpperCase(),
				date_of_birth: args.yob,
				zip_code: args.zipcode,
				referral_source: args.referralSource || "",
			};
			console.log(
				"called saveUserData:",
				args,
				JSON.stringify(user)
			);
			const requestOptions = makeRest('POST', false, user);
			const response = await fetch(
				`${process.env.REACT_APP_API_SERVER}user`,
				requestOptions
			);
			const data = await response.json();
			if (data.error) {
				const userModel = new UserModel({error: data.error});;
				return userModel;
			}
			const userModel = new UserModel(data);
			return userModel;
		} catch (err) {
			console.log(err);
			const userModel = new UserModel({error: 'Create User Failed.'});;
			return userModel;
		}
  }

  async confirmMobile(args) {
		try {
			console.log("called confirmMobile:", args);
			const requestOptions = makeRest('POST', false, {
				phone: args.phone,
				code: args.code,
			});
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}user/confirmmobile`,
					requestOptions
				);
			console.log('confirmMobile response:', response);
			const data = await response.json();
			const user = new UserModel(data);
			return user;
			
		} catch (err) {
			console.log(err);
			const user = new UserModel({error: 'SMS verification failed.'});;
			return user;
		}
  }

	async verifyMobile(args) {
		try {
			console.log("called resendMobile:", args);
			const requestOptions = makeRest('POST', false, {
				phone: args.phone,
			});
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}user/verifymobile`,
					requestOptions
				);
			console.log('verifyMobile response:', response);
			const data = await response.json();
			if (data.error) {
				throw new Error(data.error);
			}
			return data;
		} catch (err) {
			console.log(err);
			const user = {};
			user.error = 'SMS code request failed';
			return user;
		}
  }

	async getZipInfo(args) {
		try {
			console.log("called getZipInfo:", args);
			const requestOptions = makeRest('GET', false);
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}zip/${args.zipcode}`,
					requestOptions
				);
			console.log('response:', response);
			const data = await response.json();
			if (data.error) {
				throw new Error(data.error);
			}
			return data;
		} catch (err) {
			console.log(err);
			const user = {};
			user.error = 'zip code request failed';
			return user;
		}
	}

  async confirmSecondaryEmail(args) {
		try {
			console.log("called confirmSecondaryEmail:", args);
			const requestOptions = makeRest('POST', false, {
				user_id: args.userId,
				email: args.email,
				token: args.token,
				key: args.key,
				decode: args.decode
			});
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}user/confirmsecondaryemail`,
					requestOptions
				);
			const data = await response.json();
			if (data.error || data.errors) {
				throw new Error(data.error || data.errors);
			}
			return data;
		} catch (err) {
			console.log(err);  
			throw err;
		}
  }

  async updateSignature(args) {
		try {
			console.log("called updateSignature:", args);
			const requestOptions = makeRest('POST', true, {
				user_id: args.userId,
				phone: args.phone,
				signature: args.signatureImage,
				marketing_emails: args.marketingEmails
			});
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}user/${Utils.getUserId()}/signature`,
					requestOptions
				);
			const data = await response.json();
			if (data.error || data.errors) {
				throw new Error(data.error || data.errors);
			}
			return data;
		} catch (err) {
			console.log(err);
			throw err;
		}
  }

	setSessionTimeout() {
		window.setTimeout(() => {
			Utils.resetSessionStorage();
			window.location.href ="/signin";
		}, 10 * 60 * 1000);	
	}
}