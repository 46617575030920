import React from "react";
import { Container, Row, Col, } from "react-bootstrap";
import moment from 'moment';
import "./cla-details.scss";
import "./question/questions.scss";

const Lawsuit = (props) => {
	return (
		<Container className="container-lawsuit">
			<div className="deadline">
				DEADLINE
			</div>
			<div className="days-left">
				{props.claimStatusToShow}
			</div>
			<div className="lawsuit-company">
				{props.companyName}
			</div>
			<div className="lawsuit-info">
				{props.info}
			</div>
			<span className="lawsuit-payout-title">PAYOUT</span> <br />
			<span className="lawsuit-payout-amount">{props.totalPayout}</span>
		</Container>
	);
};

export default Lawsuit;

// 452px