import React, { Component } from "react";
import { Container, Button } from "react-bootstrap";
import "./aboutus.scss";

class Whoweare extends Component {

	render() {
		return (
			<Container className="container-aboutus-background">
				<div className="container-aboutus">
					<div className="header-aboutus">
						Who We Are
				</div>
				</div>
			</Container>
		)
	}
}

export default Whoweare;  