export default class EmailModel {
	constructor(data) {
		this._id = data.id || '';
		this._email = data.email || '';
		this._emailVerified = data.email_verified || '';
		this._error = data.error || '';
		this._emailPrimary = data.email_primary || '';
		this._delete = data.delete || false;
	}

	get id() {
		return this._id;
	}

	get email() {
		return this._email;
	}

	get emailVerified() {
		return this._emailVerified;
	}

	get emailPrimary() {
		return this._emailPrimary;
	}

	get error() {
		return this._error;
	}

	get delete() {
		return this._delete;
	}

	set delete(value) {
		this._delete = value;
	}
}