import React, { Component } from "react";
import { Container, Button, Row } from "react-bootstrap";
import "./aboutus.scss";

class Aboutustext extends Component {

	render() {
		return (
			<Container className="container-aboutustext-background" fluid>
				<div className="container-aboutustext">
					<div className="row" style={{ marginLeft: "0px", marginRight: "0px", paddingTop: "84px" }}>
						<div className="aboutus-whatis-header">
							What is the Data Dividend Project?
						</div>
						<div className="aboutus-whatis-text">
							Inspired by former Presidential Candidate Andrew Yang, DDP is a movement dedicated to helping Americans regain ownership and control of our personal data. Our data is our property, and we should have the choice to get privacy and to get paid.
							<br />
							<br />
							We aim to mobilize millions of Americans to collectively exercise their data rights and to get paid if they choose to share their data. 
						</div>
					</div>
					<div className="video-div">
						<iframe className="video-iframe" src='https://www.youtube-nocookie.com/embed/RQlVV45KrUY'
							frameBorder='0'
							allow='autoplay; encrypted-media'
							title='Announcing Data Dividend Project by Andrew Yang'
						/>
					</div>
				</div>
			</Container>
		)
	}
}

export default Aboutustext;  