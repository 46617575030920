import React, { Component } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Analytics from "../../commons/analytics";
import * as Utils from "../../commons/utils";
import DdpService from '../../commons/services/ddp-service';

class Information extends Component {
	constructor(props) {
		super(props);
		this.state = {
			validated: false,
			firstName: props.values.firstName,
			lastName: props.values.lastName,
			email: props.values.email,
			dob: props.values.dob,
			zipcode: props.values.zipcode,
			street: props.values.street,
			city: props.values.city,
			stateCode: props.values.state,
			phoneNumber: props.values.phone,
			checkAccepted: false,
			processing: false,
			errors: [],
			error: null,
		};
		this.analytics = new Analytics();
	}

	saveAndContinue = async (e) => {
		e.preventDefault()
		const { firstName, lastName, email, phoneNumber, dob, street, zipcode, city, stateCode, checkAccepted } = this.state;
		if (!checkAccepted) {
			this.setState({ error: "Please review and accept the DDP Terms & Conditions and the Privacy Policy to continue.", validated: false });
			return;
		}

		const errorUser = Utils.validateUserData({
			email,
			firstName,
			lastName,
			street,
			city,
			zipCode: zipcode,
			state: stateCode,
			yob: dob,
			phone: phoneNumber,
		});
		if (errorUser.length > 0) {
			let errorText = '';
			errorUser.forEach(err => errorText = `${errorText}\n${err.error}`);
			console.log(errorText)
			this.setState({
				error: errorText,
				validated: false
			});
			return;
		} else {
			this.setState({ error: '', validated: true });
		}
		try {
			this.setState({ processing: true });
			const ddpService = new DdpService();
			const user = await ddpService.createUser({
				email,
				firstName,
				lastName,
				street,
				city,
				zipcode,
				state: stateCode,
				yob: dob,
				phone: phoneNumber,
			});
			if (user.error || user.errors) {
				this.setState({ error: user.error || user.errors, processing: false, });
				return;
			}
			this.analytics.trackEvent({
				pageName: 'signUp',
				cta: 'confirmation-confirm',
				actionName: 'clicked'
			});
			console.log('saved user:', user);
			Utils.setSessionData(user, false);
			this.setState({ completed: true, error: null, processing: false, });
			this.props.nextStep();
		} catch (err) {
			console.log(err);
			this.setState({ error: err.message, processing: false });
		}
	};

	handleBlur = async (event) => {
		const errors = this.state.errors;
		if (event.target.id === 'lastName') {
			if (event.target.value.length < 2) {
				errors[event.target.id] = "Last name is required.";
			} else if (!Utils.NAME_REGEX.test(event.target.value)) {
				errors[event.target.id] = "Please enter last name (Alpha, ', -, space, only allowed).";
			} else {
				delete errors[event.target.id];
			}
		} else if (event.target.id === 'firstName') {
			if ((event.target.value.length < 2 && event.target.value.length > 0) ||
				(event.target.value.length === 0 && this.state.firstName.length > 0)) {
				errors[event.target.id] = "First name is required.";
			} else if (event.target.value.length > 0 && !Utils.NAME_REGEX.test(event.target.value)) {
				errors[event.target.id] = "Please enter first name (Alpha, ', -, space, only allowed).";
			} else {
				delete errors[event.target.id];
			}
		} else if (event.target.id === 'phoneNumber') {
			const phoneNumberParsed = parsePhoneNumberFromString(event.target.value, 'US');
			if (!phoneNumberParsed || (phoneNumberParsed && !phoneNumberParsed.isValid())) {
				errors[event.target.id] = 'Please enter valid phone number.';
			} else {
				delete errors[event.target.id];
			}
		} else if (event.target.id === 'email') {
			if (!Utils.EMAIL_REGEX.test(event.target.value) || event.target.value.length < 5) {
				errors[event.target.id] = "Please enter vaid email address.";
			} else {
				delete errors[event.target.id];
			}
		} else if (event.target.id === 'dob') {
			if (event.target.value.length < 10) {
				errors[event.target.id] = "Please enter valid Date of Birth (MM/DD/YYYY).";
			} else if (event.target.value.length >= 10) {
				const dateCheck = Utils.validateBirthDate(event.target.value);
				if (!dateCheck.valid) {
					errors[event.target.id] = "Please enter valid Date of Birth (MM/DD/YYYY).";
				} else {
					delete errors[event.target.id];
				}
			}
		} else if (event.target.id === 'zipcode') {
			if (event.target.value.length < 5) {
				errors[event.target.id] = "Zip Code is required";
			} else if (!Utils.ZIP_REGEX.test(event.target.value)) {
				errors[event.target.id] = "Please enter valid Zip Code.";
			} else {
				const ddpService = new DdpService();
				try {
					const zipDetails = await ddpService.getZipInfo({ zipcode: event.target.value });
					if (zipDetails.error || !zipDetails.city) {
						errors['zipcode'] = "Please enter valid Zip Code.";
					} else {
						delete errors['zipcode'];
						this.setState({
							city: zipDetails.city,
							stateCode: zipDetails.state
						});
					}
				} catch (err) {
					this.setState({ validated: false });
					return;
				}
			}
		} else if (event.target.id === 'street') {
			if (event.target.value.length < 2) {
				errors[event.target.id] = "Street is required.";
			} else {
				delete errors[event.target.id];
			}
		} else if (event.target.id === 'city') {
			if (event.target.value.length < 2) {
				errors[event.target.id] = "City is required.";
			} else if (!Utils.NAME_REGEX.test(event.target.value)) {
				errors[event.target.id] = "Please enter vaid City.";
			} else {
				delete errors[event.target.id];
			}
		} else if (event.target.id === 'stateCode') {
			if (event.target.value.length < 2) {
				errors[event.target.id] = "State is required.";
			} else if (!Utils.STATE_REGEX.test(event.target.value)) {
				errors[event.target.id] = "Please enter vaid State.";
			} else {
				delete errors[event.target.id];
			}
		}
		this.setState({ errors });
		this.checkAndEnable();
	}

	checkAndEnable = () => {
		const { firstName, lastName, email, phoneNumber, dob, street, zipcode, city, stateCode } = this.state;
		const errorUser = Utils.validateUserData({
			email,
			firstName,
			lastName,
			street,
			city,
			zipCode: zipcode,
			state: stateCode,
			yob: dob,
			phone: phoneNumber,
		});
		if (errorUser.length > 0) {
			this.setState({ validated: false });
		} else {
			this.setState({ error: '', validated: true });
		}
	}

	handleChange = async (event) => {
		this.setState({
			[event.target.id]: event.target.value
		});
		if (event.target.id === 'zipcode') {
			if (event.target.value.length === 5) {
				const ddpService = new DdpService();
				try {
					const zipDetails = await ddpService.getZipInfo({ zipcode: event.target.value });
					if (zipDetails.city) {
						console.log('zipDetails,', zipDetails);
						this.setState({
							city: zipDetails.city,
							stateCode: zipDetails.state
						});
					}
				} catch (err) {
					this.setState({ validated: false });
					return;
				}
			}
		}
	};

	handleCheck = (event) => {
		this.setState({
			checkAccepted: event.target.checked,
		});
		this.checkAndEnable();
	};

	render() {
		const { firstName, lastName, email, phoneNumber, dob, street, zipcode, city, stateCode, checkAccepted, errors, error, validated, processing } = this.state;
		return (
			<Form className="information-form" onSubmit={this.saveAndContinue}>
				<Form.Group className="form-group-signup ">
					<Form.Group>
						<Form.Control
							className="field-input half-input uppercase-text"
							id="firstName"
							type="text"
							placeholder="First Name"
							onChange={(e) => this.handleChange(e)}
							onBlur={(e) => this.handleBlur(e)}
							maxLength="30"
							required
							autoFocus
							value={firstName}
						/>
						{errors.firstName && (
							<Form.Text className="text-invalid half-input-error">
								{errors.firstName}
							</Form.Text>
						)}
					</Form.Group>
					<Form.Group>
						<Form.Control
							className="half-input uppercase-text input-right"
							type="text"
							id="lastName"
							placeholder="Last Name"
							onChange={(e) => this.handleChange(e)}
							onBlur={(e) => this.handleBlur(e)}
							maxLength="30"
							required
							value={lastName}
						/>
						{errors.lastName && (
							<Form.Text className="text-invalid half-input-error">
								{errors.lastName}
							</Form.Text>
						)}
					</Form.Group>
				</Form.Group>
				<Form.Group className="form-group-signup ">
					<Form.Group>
						<Form.Control
							className="full-input uppercase-text"
							type="text"
							id="email"
							placeholder="Email"
							onChange={(e) => this.handleChange(e)}
							onBlur={(e) => this.handleBlur(e)}
							maxLength="150"
							required
							value={email}
						/>
						{errors.email && (
							<Form.Text className="text-invalid full-input-error">
								{errors.email}
							</Form.Text>
						)}
					</Form.Group>
				</Form.Group>
				<Form.Group className="form-group-signup ">
					<Form.Group>
						<Form.Control
							className="full-input uppercase-text"
							type="tel"
							id="phoneNumber"
							placeholder="Phone Number"
							onChange={(e) => this.handleChange(e)}
							onBlur={(e) => this.handleBlur(e)}
							maxLength="12"
							required
							value={phoneNumber}
						/>
						{errors.phoneNumber && (
							<Form.Text className="text-invalid full-input-error">
								{errors.phoneNumber}
							</Form.Text>
						)}
					</Form.Group>
				</Form.Group>
				<Form.Group className="form-group-signup ">
					<Form.Group>
						<Form.Control
							className="full-input uppercase-text"
							type="text"
							pattern="[0-1][0-2][/-][0-3][0-9][/-][1-2]\d{3}"
							placeholder="Date of Birth (MM/DD/YYYY)"
							onChange={(e) => this.handleChange(e)}
							onBlur={(e) => this.handleBlur(e)}
							maxLength="10"
							required
							id="dob"
							value={dob}
						/>
						{errors.dob && (
							<Form.Text className="text-invalid full-input-error">
								{errors.dob}
							</Form.Text>
						)}
					</Form.Group>
				</Form.Group>
				<Form.Group className="form-group-signup ">
					<Form.Group>
						<Form.Control
							className="field-input full-input uppercase-text"
							type="text"
							placeholder="Street"
							onChange={(e) => this.handleChange(e)}
							onBlur={(e) => this.handleBlur(e)}
							maxLength="100"
							required
							id="street"
							value={street}
						/>
						{errors.street && (
							<Form.Text className="text-invalid full-input-error">
								{errors.street}
							</Form.Text>
						)}
					</Form.Group>
				</Form.Group>
				<Form.Group className="form-group-signup ">
					<Form.Group>
						<Form.Control
							className="field-input full-input uppercase-text"
							type="text"
							placeholder="Zip Code"
							onChange={async (e) => await this.handleChange(e)}
							onBlur={async (e) => await this.handleBlur(e)}
							maxLength="5"
							required
							value={zipcode}
							id="zipcode"
						/>
						{errors.zipcode && (
							<Form.Text className="text-invalid full-input-error">
								{errors.zipcode}
							</Form.Text>
						)}
					</Form.Group>
				</Form.Group>
				<Form.Group className="form-group-signup ">
					<Form.Group>
						<Form.Control
							className="field-input full-input uppercase-text"
							type="text"
							placeholder="City"
							onChange={(e) => this.handleChange(e)}
							onBlur={(e) => this.handleBlur(e)}
							maxLength="40"
							required
							value={city}
							id="city"
						/>
						{errors.city && (
							<Form.Text className="text-invalid full-input-error">
								{errors.city}
							</Form.Text>
						)}
					</Form.Group>
					</Form.Group>
					<Form.Group className="form-group-signup ">
						<Form.Group>
							<Form.Control
								className="field-input full-input uppercase-text"
								type="text"
								placeholder="State"
								onChange={(e) => this.handleChange(e)}
								onBlur={(e) => this.handleBlur(e)}
								maxLength="2"
								required
								id="stateCode"
								value={stateCode}
							/>
							{errors.stateCode && (
								<Form.Text className="text-invalid full-input-error">
									{errors.stateCode}
								</Form.Text>
							)}
						</Form.Group>
				</Form.Group>
				<Form.Group>
					<Form.Check
						className="form-check-green"
						required
						name="terms"
						value={checkAccepted}
					>
						<Form.Check.Input
							onClick={this.handleCheck} className="form-check-box-green" type="checkbox" isValid />
						<Form.Check.Label className="form-check-black-label"> By checking this box, I affirm that I have read and agree to be bound by the DDP {' '}
							<a href="/privacy?tab=terms" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>
							{' '} and the {' '}<a href="/privacy?tab=privacy" target="_blank" rel="noopener noreferrer">
								DDP Privacy Policy.</a></Form.Check.Label>
					</Form.Check>
				</Form.Group>
				<Form.Group>
					<div className="verify-info">
						To verify your mobile phone number, you will be sent a text message with a 6-digit code. Text messaging charges may apply.
					</div>
				</Form.Group>
				<Form.Group>
					{!processing &&
						<Button className="info-next-button"
							disabled={!validated}
							onClick={this.saveAndContinue}
						>
							NEXT
						</Button>
					}
					{processing &&
						<Spinner animation="grow" variant="success" className="float-right" />
					}
					{error && (
						<Form.Text className="text-invalid full-input-error">
							{error}
						</Form.Text>
					)}
				</Form.Group>
			</Form >
		);
	}
}

export default Information;