import React, { Component } from "react";
import { Container, Row, Button} from "react-bootstrap";

import "./home.scss";

class Negotiate extends Component {

	render() {
		return (
			<Container className="container-negotiate-bg" fluid>
				<div className="container-negotiate row">
					<div className="header-negotiate">We negotiate with Big Tech and Data Brokers on your behalf</div>
					<div className="negotiate-content">
						<div class="negotiate-subheader">
							WHAT WE DO
						</div>
						<div class="negotiate-text">
							Leveraging all of our voices, we will collectively negotiate with Big Tech and Data Brokers to pay us for the use of our data or we will collectively Opt Out / Delete our records from their servers, leaving them without their biggest means to make money — us.
						</div>
						<Button href="/signup" className="negotiate-button">JOIN DDP NOW</Button>
					</div>
				</div>
			</Container>
		);
	}
}

export default Negotiate;