import React from 'react';
import {
  Route,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import Home from "./pages/home/home";
import Signup from "./pages/signup/signup";
import Contact from "./pages/contact/contact";
import PrivacyContainer from "./pages/privacy/privacycontainer";
import ConfirmEmail from "./pages/confirmemail/confirmemail";
import ThankYou from "./pages/thankyou/thankyou";
import ComingSoon from "./pages/thankyou/comingsoon";
import Manifesto from "./pages/manifesto/manifesto";
import ClassAction from './pages/classactions/classaction/classaction';
import ClaDetails from "./pages/classactions/cla-details/cla-detais";
import Signin from "./pages/account/sign-in/sign-in";
import UserDetails from "./pages/account/user-details/user-details";
import UserEmails from "./pages/account/user-emails/user-emails";
import AboutUs from "./pages/aboutus/aboutus";


function App() {
  return (
		<div className="App">
			<BrowserRouter>
				<Switch>
					<Route path="/aboutus">
						<AboutUs />
					</Route>
					<Route path="/classactions">
						<ClassAction />
					</Route>
					<Route path="/classactionsyou">
						<ClassAction />
					</Route>
					<Route path="/contact">
						<Contact />
					</Route>
					<Route path="/signup">		
 						<Signup />		
 					</Route>				
 					<Route path="/confirmemail">		
 						<ConfirmEmail />		
 					</Route>		
 					<Route path="/thankyou">		
 						<ThankYou />		
 					</Route>		
 					<Route path="/comingsoon">		
 						<ComingSoon />		
 					</Route>		
 					<Route path="/privacy">		
 						<PrivacyContainer />		
 					</Route>			
 					<Route path="/manifesto">		
 						<Manifesto />		
 					</Route> 			
					 <Route path="/cladetails/:claims">		
 						<ClaDetails />		
 					</Route> 
					<Route path="/signin">		
 						<Signin />		
 					</Route> 
					<Route path="/details">		
 						<UserDetails />		
 					</Route> 
					<Route path="/emails">		
 						<UserEmails />		
 					</Route>
 					<Route path="/">		 				
 						<Home />	
 					</Route>
				</Switch>
			</BrowserRouter>
		</div>
  );
}

export default App;
