import React, { Component } from "react";
import { Container, Row, Button } from "react-bootstrap";
import styled from "styled-components";
import "./classaction.scss";
import ClaimService from "../../../commons/services/claim-service";

const MainContainer = styled.div`
  width: 50%;
  height: 100%;
  // margin-top: 38px;
  background-color: rgba(0, 0, 0, 0.75);
	background-image: ${props => `url('${props.desktopBackground}')`} !important;
  background-position: center;
  background-repeat: no-repeat;
	background-size:100%
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.0460009);
	border-top-left-radius: 8px;
  border-bottom-left-radius:8px;
  @media (max-width: 1023px) { 
  width: 343px;
	height:173px;
	display:inline-block;
	background-size:100%;
		border-top-left-radius: 8px;
  border-top-right-radius:8px;
  border-bottom-left-radius:1px;
  }
  z-index: 10;
`;

class ActNow extends Component {
	constructor(props) {
		super(props);
		this.classActions = (new ClaimService()).classActions();
	}

	render() {
		console.log(this.classActions.filter(action => action.group === "Open Claims"));
		return (
			<Container className="container-actnow-background" fluid>
				<div className="container-actnow">
					<div className="actnow-content row" >
						<div className="actnow-content-header">
							If your data was abused, get your fair share. 
						</div>
						<div className="actnow-content-text">
							We don’t want you to miss out on any chances to get compensated for past data abuses. 
							<br />
							<br />
							Millions of dollars go unclaimed every year. Use our simple Settlements Claims Tool to find out if you’re entitled to receive money or benefits from one of the settlements listed below.
						</div>
					</div>
					<div className="card-actnow">
						<div className="header-actnow">
							Active Claims
					</div>
						{
							this.classActions.filter(action => action.group === "Open Claims").map(classAction => (
								<React.Fragment key={classAction.companyName}>
									<div className="classaction-card row" href={classAction.redirectUrl}>
										<MainContainer desktopBackground={classAction.desktopBackground} />
										<div className="info-container">
											<div className="card-info">
												<div className="card-info-time">{classAction.claimStatusToShow}</div>
												<div className="card-info-header">{classAction.companyName}&nbsp;</div>
												<div className="card-info-subheader">{classAction.details}</div>
												<div className="card-info-payout">PAYOUT</div>
												<div className="card-info-text">{classAction.payoutInfo}</div>
												<div className="card-info-button">
													<Button href={classAction.redirectUrl} className="claim-button">GET YOUR MONEY</Button>
												</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							))
						}
						<div className="header-actnow">
							Past Claims
					</div>
						{
							this.classActions.filter(action => action.group === "Past Claims").map(classAction => (
								<React.Fragment key={classAction.companyName}>
									<div className="classaction-card row" href={classAction.redirectUrl}>
										<MainContainer desktopBackground={classAction.desktopBackground} />
										<div className="info-container">
											<div className="card-info">
												<div className="card-info-time">{classAction.claimStatusToShow}</div>
												<div className="card-info-header">{classAction.companyName}&nbsp;<i className="arrow right"></i></div>
												<div className="card-info-subheader">{classAction.details}</div>
												<div className="card-info-payout">PAYOUT</div>
												<div className="card-info-text">{classAction.payoutInfo}</div>
												<div className="card-info-button">
													<Button className="claim-button" disabled>EXPIRED</Button>
												</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							))
						}
					</div>
				</div>
			</Container>
		);

	}
}

export default ActNow;  