import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Footer from "../../../commons/components/footer/footer";
import Header from "../../../commons/components/header/header";
import DdpService from "../../../commons/services/ddp-service";
import Analytics from "../../../commons/analytics";
import * as Utils from "../../../commons/utils";
import "./sign-in.scss";

class SignIn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			phone: '',
			code: '',
			phoneValid: false,
			completed: false,
			errorText: '',
		};
		this.analytics = new Analytics();
		this.handleCodeChange = this.handleCodeChange.bind(this);
		this.handlePhoneChange = this.handlePhoneChange.bind(this);
		this.sendCode = this.sendCode.bind(this);
	}

	handlePhoneChange = async (event) => {
		if (event.target.value && event.target.value.length === 10) {
			this.setState({
				phoneValid: false,
				phone: event.target.value,
				code: '',
				errorText: '.',
			});
		} else if (event.target.value && event.target.value.length < 10) {
			this.setState({
				phoneValid: false,
				phone: event.target.value,
				code: '',
				errorText: 'Please enter valid phone number.',
			});
		}
	};

	sendCode = async (event) => {
		if (this.state.phone.length === 10) {
			const phoneNumber = parsePhoneNumberFromString(this.state.phone, 'US');
			console.log('phoneNumber', phoneNumber);
			if (phoneNumber && phoneNumber.isValid()) {
				this.setState({
					phone: phoneNumber.nationalNumber,
					phoneValid: true,
					errorText: 'SMS Code Sent',
				}, () => this.requestResend(phoneNumber.nationalNumber));
			} else {
				this.setState({
					phoneValid: false,
					phone: '',
					code: '',
					errorText: 'Please enter valid phone number.',
				});
			}
		} else if (this.state.phone.length < 10) {
			this.setState({
				phoneValid: false,
				code: '',
				errorText: 'Please enter valid phone number.',
			});
		}
	};


	handleCodeChange = (event) => {
		if (event.target.value && event.target.value.length === 6) {
			this.setState({
				code: event.target.value,
				errorText: '',
			});
		} else if (event.target.value.length < 6) {
			this.setState({
				code: event.target.value,
				errorText: 'Please enter confirmation code.',
			});
		}
	};

	saveAndContinue = async (e) => {
		try {
			Utils.resetSessionStorage();
			if (!this.state.phone) {
				this.setState({
					validated: false,
					phone: '',
					code: '',
					errorText: 'Please enter valid phone number.',
				});
				return;
			}
			if (!this.state.code || (this.state.code && this.state.code.length < 6)) {
				this.setState({
					code: '',
					errorText: 'Please enter SMS code.',
				});
				return;
			}
			if (this.state.code && this.state.code.length === 6) {
				const ddpService = new DdpService();
				const user = await ddpService.confirmMobile({
					phone: this.state.phone,
					code: this.state.code,
				});
				console.log("Signin user:", user);
				if (user.error || !user.userId) {
					this.setState({
						completed: false,
						errorText: 'SMS validation failed.',
					});
					e.preventDefault();
					return;
				}
				this.analytics.trackEvent({
					pageName: 'signIn',
					cta: 'login',
					actionName: 'clicked'
				});
				Utils.setSessionData(user, true);
				this.setState({ completed: true, errorText: '' });
				ddpService.setSessionTimeout();
			} else {
				this.setState({
					completed: false,
					errorText: 'Please enter confirmation code.',
				});
			}
		} catch (err) {
			this.setState({
				completed: false,
				confirmationCode: '',
				errorText: 'Please enter valid confirmation code'
			});
		}
		e.preventDefault();
	};

	requestResend = async (phone = this.state.phone) => {
		try {
			if (this.state.phoneValid) {
				const ddpService = new DdpService();
				Utils.resetSessionStorage();
				const user = await ddpService.verifyMobile({
					phone: phone,
				});
				console.log('user;', user);
			}
		} catch (err) {
			this.setState({
				completed: false,
				confirmationCode: '',
				errorText: 'Request SMS code failed.'
			});
		}
	};

	render() {
		const { phone, code, phoneValid } = this.state;

		if (this.state.completed) {
			return <Redirect to="/details" />;
		}
		return (
			<React.Fragment>
				<Container className="container-signin-bg" style={{overflow:"hidden"}} fluid>
					<Header white={true} 	textBlack={true} />
					<div className="container-signin row">
						<div className="signin-container-content">
							<div className="signin-header">Sign In</div>
							<div className="signin-text">In order to Sign-In, you must have created a DDP account in the past.If you do NOT already have a DDP account, then please click here to create an account.</div>
						</div>
						<div className="signin-form-container">
							<div className="container-signin-form">
								<Form className="signin-form" onSubmit={this.saveAndContinue}>																							
									<Form.Group
										controlId="formBasicPhone"
										className="form-group-signin  col-text-maxwidth"
									>
										<Form.Label className="col-text-bold">Mobile Phone Number</Form.Label>
									</Form.Group>
									<Form.Group
										className="form-group-signin  col-text-maxwidth"
									>
										<Form.Control
											className="col-text uppercase-text col-text-maxwidth"
											type="tel"
											placeholder="10 digit phone number"
											onChange={(e) => this.handlePhoneChange(e)}
											maxLength="10"
											required
											autoFocus
											defaultValue={phone}
										/>
									</Form.Group>
									<Form.Group
										className="form-group-signin col-text-maxwidth"
									>
										<Button
											className="signin-button col-text-maxwidth"
											onClick={this.sendCode}
										>
											SEND SMS CODE
							</Button>
									</Form.Group>
									<Form.Group
										controlId="formBasicSMS"
										className="form-group-signin  col-text-maxwidth"
									>
										<Form.Label className="col-text-bold" disabled={!phoneValid}>SMS Code</Form.Label>
									</Form.Group>
									<Form.Group
										className="form-group-signin  col-text-maxwidth"
									>
										<Form.Control
											className="col-text uppercase-text col-text-maxwidth"
											type="number"
											placeholder="Code"
											onChange={(e) => this.handleCodeChange(e)}
											maxLength="6"
											required
											autoFocus
											value={code}
											disabled={!phoneValid}
										/>
										{this.state.errorText && (
											<Form.Text className="verify-error-text">
												{this.state.errorText}
											</Form.Text>
										)}
									</Form.Group>
									<Form.Group
										className="form-group-signin col-text-maxwidth"
									>
										<Button
											className="signin-button col-text-maxwidth"
											disabled={!phoneValid}
											onClick={this.saveAndContinue}
										>
											SIGN-IN
							</Button>
									</Form.Group>
								</Form>
							</div>
						</div>
					</div>
				</Container>
				<Footer />
			</React.Fragment>
		);
	}
}

export default withRouter(SignIn);
