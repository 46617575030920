import * as Utils from "../utils";
import UserModel from "../model/user";
import UserEmailModel from "../model/user-email";
import makeRest from "./rest.js";
import moment from 'moment';

export default class AccountService {
	async getUser() {
		try {
			console.log("called getUser");
			const requestOptions = makeRest('GET', true);
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}user/${Utils.getUserId()}`,
					requestOptions
				);
			const data = await response.json();
			if (data.error) {
				const user = new UserModel({error: data.error});;
				return user;
			}
			const user = new UserModel(data);
			return user;
		} catch (err) {
			console.log(err);
			const user = new UserModel({error: 'User Data Fetch failed.'});;
			return user;
		}
  }

	async updateUser(user) {
		try {
			const requestOptions = makeRest('POST', true, user);
			const response = await fetch(
				`${process.env.REACT_APP_API_SERVER}user/${Utils.getUserId()}`,
				requestOptions
			);
			const data = await response.json();
			const userModel = new UserModel(data);
			return userModel;
		} catch (err) {
			console.log(err);
			const user = new UserModel({error: 'User Data update failed.'});;
			return user;
		}
  }

	async resendEmailVerification(email) {
		try {
			console.log("called resendMobile:", email);
			const requestOptions = makeRest('POST', true, {
				email: email,
				user_id: Utils.getUserId(), 
			});
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}user/${Utils.getUserId()}/email/verify`,
					requestOptions
				);
			const data = await response.json();
			if (data.error) {
				throw new Error(data.error);
			}
			return data;
		} catch (err) {
			console.log(err);
			const user = {};
			user.error = 'SMS code request failed';
			return user;
		}
  }

	async getUserEmails() {
		try {
			const requestOptions = makeRest('GET', true);
			const response = await fetch(
				`${process.env.REACT_APP_API_SERVER}user/${Utils.getUserId()}/email`,
				requestOptions
			);
			const data = await response.json();
			const userEmailModel = new UserEmailModel(data);
			return userEmailModel;
		} catch (err) {
			console.log(err);
			const userEmailModel = new UserEmailModel({error: 'Get User Emails fetch failed.'});;
			return userEmailModel;
		}
  }

	async addEmails(emails) {
		try {
			console.log("called addEmails:", emails);
			const requestOptions = makeRest('POST', true, {
				emails: emails,
			});
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}user/${Utils.getUserId()}/email`,
					requestOptions
				);
			const data = await response.json();
			if (data.error || data.errors) {
				throw new Error(data.error || data.errors);
			}
			return data;
		} catch (err) {
			console.log(err);  
			throw err;
		}
  }

	async deleteEmails(emails) {
		try {
			console.log("called addEmails:", emails);
			const requestOptions = makeRest('POST', true, {
				emails: emails,
			});
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}user/${Utils.getUserId()}/email/delete`,
					requestOptions
				);
			const data = await response.json();
			if (data.error || data.errors) {
				throw new Error(data.error || data.errors);
			}
			return data;
		} catch (err) {
			console.log(err);  
			throw err;
		}
  }

	async resendProxy() {
		try {
			const requestOptions = makeRest('GET', true);
			const response = await fetch(
				`${process.env.REACT_APP_API_SERVER}user/${Utils.getUserId()}/proxy`,
				requestOptions
			);
			const data = await response.json();
			const userModel = new UserModel(data);
			return userModel;
		} catch (err) {
			console.log(err);
			const userModel = new UserModel({error: 'Request to resend proxy failed.'});;
			return userModel;
		}
  }

	async acceptTerms() {
		try {
			console.log("called acceptTerms:");
			const requestOptions = makeRest('POST', true, {
				terms_accepted_at: moment().format("MM/DD/yyyy"),
			});
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}user/${Utils.getUserId()}/terms`,
					requestOptions
				);
			const data = await response.json();
			if (data.error || data.errors) {
				throw new Error(data.error || data.errors);
			}
			return data;
		} catch (err) {
			console.log(err);  
			throw err;
		}
  }
}