import React from "react";
import { Container } from "react-bootstrap";
import "./thankyou.scss";

const Spread = () => {

	return (
		<Container className="container-spread" fluid>
			<div className="spread-title">
				SPREAD THE MOVEMENT. <br />
			</div>
			<br></br>
			<br></br>
			<div className="spread-text">YOUR DATA IS YOUR RIGHT.</div>
		</Container>
	);

}  

export default Spread;