import React from "react";
import { Container } from "react-bootstrap";
import "./thankyou.scss";

const VerifyEmails = () => {

	return (
		<Container className="container-verify-emails" fluid>
			<div className="verify-emails-dontforget">
				DON'T FORGET
			</div>
			<div className="verify-emails-title" >
				Verify emails
			</div>
			<div className="verify-emails-text" >
				Please visit your other email accounts to verify them. It is critical that you verify as many as you can. 
			</div>
		</Container>
	);

}  

export default VerifyEmails;