import React, { Component } from "react";
import { Container, Button} from "react-bootstrap";
import "./home.scss";

class Movement extends Component {

	render() {
		return (
			<Container className="container-movement" fluid>
				<div className="card-movement">
					<div className="movement-text">
						DDP started this movement because we believe that if companies are taking and making money off your data, they should get your consent. You should have the choice to keep your data private or to get paid.
					</div>
					<Button href="/signup" className="button-movement">JOIN DDP NOW</Button>
				</div>
			</Container>
		);
	}
}

export default Movement;