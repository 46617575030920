import React from "react";
import { Container } from "react-bootstrap";
import "./thankyou.scss";
const Movement = () => {

	return (
		<Container className="container-celebrate" fluid>
			<div className="card-celebrate" >
				<div className="celebrate-rectangle">CELEBRATE</div>
			</div>
			<div className="welcome-header" >
				WELCOME TO THE MOVEMENT!
			</div>
			<div className="confirmation-email" >
				A confirmation email with your tracking number has been sent to the first email you input.
			</div>
			<div className="card-readbelow" >
					<div className="readbelow-rectangle">READ BELOW</div>
			</div>
		</Container>
	);

}  

export default Movement;