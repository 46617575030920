import React, { Component } from "react";
import { Container, Grid, Row, Col, Button } from "react-bootstrap";
import "./home.scss";
import Analytics from "../../commons/analytics";

class Landing extends Component {
	analytics = new Analytics();

	handleJoin = (e) => {
		this.analytics.trackEvent({
			pageName: 'homepage',
			cta: 'jointhemovement',
			actionName: 'clicked'
		});
		window.location.href = "/signup";
	}

	render() {
		return (
			<Container className="container-landing-background" fluid>
				<Container className="container-landing">
					<div className="card-landing">
						<div className="card-landing-header">
							Take control <br />
					of your data
					</div>
						<div className="card-landing-subheader">
							Get privacy and get paid.
					</div>
						<Button href="/signup" className="button-landing">JOIN DDP NOW</Button>
					</div>
				</Container>
			</Container>
		);
	}
}

export default Landing;