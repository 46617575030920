import React, { Component } from "react";
import Footer from "../../commons/components/footer/footer";


class Contact extends Component {
  render() {
    return (
      <div>
        <h2>contact us</h2>
        <p>LET GETS YOU PAID.</p>

        <a href="/signup">START</a>
        <Footer />
      </div>
    );
  }
}

export default Contact;