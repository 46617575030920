import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Lottie from "lottie-react";
import dataAnim from "../../assets/dataanim.json";
import mobiledataAnim from "../../assets/mobiledata.json";
import "./home.scss";
import { gsap } from "gsap";
import lottie from "lottie-web";
import { ScrollTrigger } from "gsap/ScrollTrigger";


var anim;

class Data extends Component {

	constructor(props) {
		super(props)

		this.state = {
			style: {}
		}
		this.scene = null;
		// this.animScroll = null;
		this.tl = null;
		// this.controller = new ScrollMagic.Controller();
		gsap.registerPlugin(ScrollTrigger);
	}

	componentDidMount() {
		const height = document.getElementById('data-animation').clientHeight;
		const width = document.getElementById('data-animation').clientWidth;
		this.setState({
			style: {
				height: height,
				width: width
			}
		});
		if (width >= 1024) {
			const animData = {
				container: document.getElementById('data-animation'),
				animationData: dataAnim,
				renderer: 'svg',
				loop: false,
				autoplay: false,
				name: "animScroll",
			}
			anim = lottie.loadAnimation(animData);
			let timeObj = { currentFrame: 0 };
			ScrollTrigger.create({
				trigger: '#container-data-bg',
				scrub: true,
				pin: true,
				start: "top top",
				end: 4000,
				pinSpacing: "margin",
				onUpdate: self => {
					gsap.to(timeObj, {
						duration: 4,
						currentFrame: (Math.floor(self.progress * (anim.totalFrames - 1))),
						onUpdate: () => {
							anim.goToAndStop(timeObj.currentFrame, true)
						},
						ease: 'expo',
					})
				},
				// pinSpacing: false 
				//  onRefresh: self => self.progress === 1 && self.animation.progress(1)
			});
		} 
	}

	render() {
		return (
			<Container className="container-data-bg" id="container-data-bg" fluid>
				<div className="container-data">
					<div className="data-content">
						<div className="data-header">
							Big tech and Data Brokers are making billions off your data per year
							</div>
						<div className="data-text">
							They track and monetize your every move online. Without giving you a dime.
							</div>
					</div>
					<div className="data-animation" style={this.state.style} id="data-animation">
					</div>
					<div className='data-animation-mobile' id="data-animation-mobile" />
				</div>
			</Container >
		);
	}
}

export default Data;