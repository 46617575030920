import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";

import "./home.scss";

class Circle extends Component {

	render() {
		return (
			<Container className="container-circle-bg" fluid>
				<div className="container-circle">
					<div className="circle-text-container">
						<div className="circle-text">
							Alone, we are no match for Big Tech and Data Brokers,but together we are a force to be reckoned with.
						</div>
					</div>
				</div>
			</Container>
		);
	}
}

export default Circle;