import React, { Component } from "react";
import { Container, ProgressBar } from "react-bootstrap";
import QueryString from "query-string";
import { withRouter } from "react-router-dom";
import Footer from "../../commons/components/footer/footer.jsx";
import Information from "./information.jsx";
import VerifyPhone from "./verifyphone.jsx";
import Emails from "./emails.jsx";
import Signature from "./signature.jsx";
import "./signup.scss";
import Header from "../../commons/components/header/header.jsx";

// createStore({
//     data: {}
// });

class Signup extends Component {
    constructor(props) {
        super(props);
        const values = QueryString.parse(this.props.location.search);
        const refSource = values.ref;
        console.log('referral source:', refSource);
        this.state = {
            step: 0,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            zipcode: "",
            yob: "",
            street: "",
            city: "",
            state: "",
            referralSource: refSource || "",
            progressbg1: "#60D78A",
            progressbg2: "#F2F2F2",
            progressbg3: "#F2F2F2",
            progressbg4: "#F2F2F2",
        };
        this.handleChange = this.handleChange.bind(this);
        this._next = this._next.bind(this);
    }

    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    handleSubmit = event => {
        event.preventDefault()
        const { email, username, password } = this.state
        alert(`Your registration detail: \n 
           Email: ${email} \n 
           Username: ${username} \n
           Password: ${password}`)
    }

    _next = () => {
        let currentStep = this.state.step
        currentStep = currentStep >= 2 ? 3 : currentStep + 1
        this.setState({
            step: currentStep
        });
        console.log('next step', this.state.currentStep);
    }

    _prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    renderStep(step, values) {
        console.log('renderstep');
        switch (step) {
            case 0:
                return (
                    <Information nextStep={this._next} handleChange={this.handleChange} values={values} />
                );
            case 1:
                return (
                    <VerifyPhone nextStep={this._next} handleChange={this.handleChange} values={values} />
                );
            case 2:
                return (
                    <Signature nextStep={this._next} handleChange={this.handleChange} values={values} />
                );
            default:
                return (
                    <Emails nextStep={this._next} handleChange={this.handleChange} values={values} />
                );
        }
    }

    renderProgressBar(step) {
			console.log(step, 'steppepe', ((step + 1)/4 * 100))
			return (
				<div className="justify-content-left progress-container">
					<ProgressBar
						className="progress-bar-border"
						variant="success"
						now={((step + 1)/4 * 100)}
					></ProgressBar>
				</div>
			)
    }

    render() {
        const { step } = this.state;
        const { firstName, lastName, email, phone, zipcode, yob, state, street, city } = this.state;
        const values = { firstName, lastName, email, phone, zipcode, yob, state, street, city };
        return (
            <React.Fragment>
                <Container className="signup-container-background" style={{ overflow: "hidden" }} fluid >
                    <Header white={true} textBlack={true} />
                    <div className="signup-container row">
                        <div className="signup-information">
                            <div className="signup-text-container">
                                <div className="signup-header">
                                    What does joining DDP mean for me? <br />
                                </div>
                                <div className="signup-subheader">
                                    In the following few pages, you will be asked to review and sign a short written authorization giving DDP the authority to act as your “authorized agent” so DDP can help exercise your data rights under the CCPA.
                                    Please note that this written authorization is only for DDP to act in a limited area--only as it concerns your data rights, and nothing else.
                                </div>
                            </div>
                        </div>
												<div className="signup-step-wrapper">
													{ this.renderProgressBar(step)}
													<div key={this.state.step} className="signup-step-container">
															{this.renderStep(this.state.step, values)}
													</div>
												</div>
                    </div>
                </Container>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withRouter(Signup);