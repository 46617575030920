import * as Utils from "../utils";
export default function makeRest(method, token, body = null) {
	const Authorization = token ? Utils.getAuthToken() : process.env.REACT_APP_API_KEY;
	const requestOptions = {
		method: `${method}`,
		headers: { 
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Bearer ${Authorization}`
		},
	};
	if (body) {
		requestOptions.body = JSON.stringify(body);
	}
	return requestOptions;
}