import React, { useState } from "react";
import { Container, Row, Table, Button } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import "./questions.scss";

const Question = (props) => {
	const [answer, setAnswer] = useState(null);

	const saveAndContinue = (e) => {
		if (answer) {
			console.log('answer:', answer);
			props.handleChange(answer);
			setAnswer(null);
		}
	};

	return (
		props.question ?
			<Container className="container-question">
				<div className="question-reset" onClick={props.handleReset}>
					<div className="question-reset-tooltip">Start Questionnaire Over</div>
				</div>
				<Row className="question-header justify-content-center">
					{props.question.header}
				</Row>
				<Row className="question-text justify-content-center">
					<ReactMarkdown source={props.question.description} linkTarget={'_blank'} />
				</Row>
				<Row className={props.question.optionsAsRow ? "question-row-mode justify-content-center" : "question-col-mode justify-content-center"}>
					{
						props.question.answers.map(currentAnswer => (
							<Row
								key={Math.floor(Math.random() * 10000)}
								className={"justify-content-center " + (props.question.optionsAsRow ? 'question-col-full ' : 'question-col-half ') + (answer === currentAnswer.heading ? 'border-green' : '')}
								onClick={() => setAnswer(currentAnswer.heading)}>

								<Table className="solution-instruction-col" >
									<tbody>
										<tr className="solution-instruction-tr">
											{
												!currentAnswer.url &&
												<td className="solution-instruction-td">
													<div className="question-answer-header">{currentAnswer.heading}</div>
													<div className="question-answer-desc">{currentAnswer.description}</div>
												</td>
											}
											{
												currentAnswer.url &&
												<React.Fragment>
													<td className="solution-instruction-td">
														<a className="no-underline" href={currentAnswer.url} target="_blank" rel="noopener noreferrer">
															<div className="question-answer-header">{currentAnswer.heading}</div>
															<div className="question-answer-desc">{currentAnswer.description}</div>
														</a>
													</td>
													<td className="solution-instruction-td solution-instruction-td-green">
														<a href={currentAnswer.url} target="_blank" rel="noopener noreferrer"><div className="question-external-arrow" /></a>
													</td>
												</React.Fragment>
											}
										</tr>
									</tbody>
								</Table>
							</Row>
						))
					}
				</Row>
				{
					answer &&
					<Row className="button-margin  justify-content-center">
						<Button className="question-button" type="submit" onClick={saveAndContinue}>
							NEXT
								</Button>
					</Row>
				}

			</Container>
			: <br />
	);
};

export default Question;