
import React from "react";
import { Modal } from "react-bootstrap";

export const LegalContractModal = (props) => {
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter" className="legalcontract-header">
					DDP Legal Agreement
        </Modal.Title>
			</Modal.Header>
			<Modal.Body className="legalcontract-body">
				<p>
					I, _________________________, as Data Owner, residing at ____________
	___________________________________________, in the city and State of ______________________, hereby designate and appoint the Data Dividend Project, PBC, a Delaware public benefit corporation doing business in California as Data Dividend Project (hereinafter referenced as “DDP”), as my authorized agent to exercise my data rights under any U.S. or State data privacy laws, including the California Consumer Privacy Act.
        </p>
				<p>
					I understand that such rights may include the right to request my data from companies and organizations,
					the right to request deletion of my data, and the right to opt-out of the sale of my data.
					I understand that I am granting DDP, as my authorized agent,
					the power to make requests to companies and organizations concerning my data rights on my behalf.
        </p>
				<p>
					By this agreement, I understand that DDP is not my attorney, and that it may refer me to outside lawyers if deemed reasonably necessary.
					I also understand that I have, at all times, the right to seek the advice of any attorney that I choose.
				</p>
				<p>
					This agreement begins on the date of execution stated below and continues in full force and effect until and unless it is revoked by me or by DDP.
					I hereby revoke any previous agreements granting permission to anyone else to act as my authorized agent when it comes to my data rights.
				</p>
				<p>
					I intend for and agree to the electronic execution of this appointment.
					<br />
					<br />
					Electronically signed on the date ________________________.
					<br />
				</p>
			</Modal.Body>
		</Modal>
	);
}


