import React, { Component } from "react";
import { Container, Button, Row } from "react-bootstrap";
import "./aboutus.scss";

class Aboutustextgrey extends Component {

	render() {
		return (
			<Container className="container-aboutustext-background container-aboutustextgrey-background" fluid>
				<div className="container-aboutustext container-aboutustextgrey">
					<div className="row" style={{ marginLeft: "0px", marginRight: "0px", paddingTop: "80px" }}>
						<div className="aboutus-whatis-header">
							What does DDP actually do for me?
						</div>
						<div className="aboutus-whatis-text" style={{ height: "420px" }}>
							DDP is building a movement of Americans who are taking a stand against data brokers and tech companies who are taking advantage of their unprotected data.
							<br />
							<br />
							By signing up with DDP, you give us the ability to collectively bargain and collectively exercise your data rights. DDP will harness that combined power to Opt Out of the sale and sharing of your data and to demand deletion of your data. Or should you choose, we will get you paid for the use of your data. With a critical mass of consumers exercising their data rights en masse, tech companies will no longer be able to get away with hoarding the gains made off your data.
							<br />
							<br />
							Individually, we are powerless to stand up to big tech and data brokers. Together, however, we can regain what is rightfully ours.{' '}
							<a className="aboutus-link" href="/signup">Join the DDP Movement</a>.
						</div>
					</div>
				</div>
			</Container>
		)
	}
}

export default Aboutustextgrey;  