import * as detailsData from './details';


export const ClassActionFactory = (classActionName) => {
	const {
		equifaxData,
		facebookData,
		// googleData,
	} = detailsData;
	switch (classActionName.toLowerCase()) {
		case 'facebook': 
			return facebookData;
		case 'equifax':
			return equifaxData;
		default:
			return null;
	}
};