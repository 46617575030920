import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";

import "./home.scss";

class Claims extends Component {

	render() {
		return (
			<Container className="container-claims-bg" fluid>
				<div className="container-claims row">
					<div className="container-claims-text">
						<div className="claims-subheader">Get your fair share now</div>
						<div className="claims-text">Your data has been used and abused for years. There are multiple cash settlements that have been reached with tech companies for these past abuses. You may be entitled to a payout; make your claim now.</div>
						<a href="/classactions"><div className="claims-seemore">See ALL data rights Claims</div></a>
					</div>
						<a href="/claimstool" className="equifax-claim">
							<div className="claim-home-container">
								<div className="claim-home-deadline-header">DEADLINE</div>
								<div className="claim-home-deadline">Nov 23, 2020</div>
								<div className="claim-home-header">Equifax</div>
								<div className="claim-home-payout-header">PAYOUT</div>
								<div className="claim-home-payout">$650M</div>
							</div>
						</a>
				</div>
			</Container>
		);
	}
}

export default Claims;