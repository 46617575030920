import React, { Component } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { Redirect, withRouter } from "react-router-dom";
import AccountService from "../../commons/services/account-service";
import Analytics from "../../commons/analytics";
import UserEmailModel from "../../commons/model/user-email";
import * as Utils from "../../commons/utils";
import "./signup.scss";

class Emails extends Component {
	constructor(props) {
		super(props);
		const userId = window.sessionStorage.getItem("userid");
		this.state = {
			userId,
			emails: [],
			email: '',
			error: false,
			errorText: 'Please enter a valid email',
			completed: false,
			ddpSupported: window.sessionStorage.getItem("ddpSupported"),
		};
		this.analytics = new Analytics();
		this.handleChange = this.handleChange.bind(this);
		this.saveAndContinue = this.saveAndContinue.bind(this);
		this.submit = this.submit.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	handleChange = (event) => {
		if (event.target.attributes['data-id'].value === 'new-email' || !event.target.attributes['data-id']) {
			this.setState({ email: event.target.value });
		} else {
			const emails = [...this.state.emails];
			console.log(emails, event.target.attributes['data-id'].value, event.target.value);
			if (event.target.value.length === 0) {
				delete emails[emails.indexOf(event.target.attributes['data-id'].value)];
			} else {
				emails[event.target.attributes['data-id'].value] = event.target.value;
			}
			this.setState({
				emails,
				email: '',
			});
			console.log(emails);
		}
	};

	saveAndContinue = async (e) => {
		e.preventDefault();
		try {
			let error = true;
			let email = this.state.email;
			let errorText = 'Please enter a valid email address.';
			const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			const valid = EMAIL_REGEX.test(this.state.email)
			if (!valid) {
				this.setState({
					error,
					errorText,
				});
				return;
			}
			errorText = 'Email already exists';
			const emails = [...this.state.emails];
			console.log('emails:', emails);
			if (emails.indexOf(this.state.email) < 0) {
				emails.push(this.state.email);
				this.analytics.trackEvent({
					pageName: 'collectemails',
					cta: 'addemails',
					actionName: 'clicked'
				});
				error = false;
				email = '';
				errorText = '';
			}
			this.setState({
				emails,
				email,
				error,
				errorText,
			});
		} catch (err) {
			console.log(err);
		}
		e.preventDefault();
	};

	submit = async (e) => {
		e.preventDefault();
		console.log('submit called');
		try {
			if (this.state.emails.length > 0) {
				try {
					const accountService = new AccountService();
					const userEmail = new UserEmailModel({ userId: Utils.getUserId(), });
					this.state.emails.forEach(email => userEmail.addEmail(email));
					const user = await accountService.addEmails(userEmail.getDataForUpdate());
					this.analytics.trackEvent({
						pageName: 'collectemails',
						cta: 'finished',
						actionName: 'clicked'
					});
					console.log("collectemails user:", user);
					this.setState({ completed: true });
				} catch (err) {
					console.log(err);
					this.setState({ completed: false });
				}
			} else {
				this.setState({ completed: true });
			}
		} catch (err) {
			console.log(err);
			this.setState({ completed: false, errorText: err.message });
		}
	};

	handleKeyPress = async (e) => {
		const key = e.keyCode || e.which;
		if (key === 13) {
			await this.saveAndContinue(e);
		}
	}

	render() {
		const { completed, ddpSupported, emails } = this.state;
		if (completed)
			return <Redirect to="/thankyou" />;

		return (
			<Container className="container-emails" fluid>
				<Form
					className="form-group-collect"
					onSubmit={this.submit}
				>
					<Form.Group>
						<Form.Text className="emails-heading">Please enter additional emails</Form.Text>
					</Form.Group>
					{
						emails.map((emailToShow, index) => (
							<Form.Group>
								<Form.Control
									className="field-input-email"
									type="email"
									placeholder="Enter email"
									onChange={(e) => this.handleChange(e)}
									onKeyPress={(e) => this.handleKeyPress(e)}
									maxLength="150"
									required
									data-id={index}
									defaultValue={emailToShow}
									key={emailToShow}
								/>
							</Form.Group>
						))
					}
					<Form.Group>
						<Form.Control
							className="field-input-email"
							type="email"
							placeholder="Enter email"
							autoFocus
							onChange={(e) => this.handleChange(e)}
							onKeyPress={(e) => this.handleKeyPress(e)}
							maxLength="150"
							required
							data-id='new-email'
							value={this.state.email}
						/>
						{this.state.error && (
							<Form.Text className="text-invalid">
								{this.state.errorText}
							</Form.Text>
						)}
						</Form.Group>
						<Form.Group>
						{
							(this.state.email.length > 0) && (
								<Button className="button-emails button-addemails" onClick={this.saveAndContinue}>Add Email</Button>
							)}
						{
							this.state.email.length === 0 && (
								<Button className="button-emails" onClick={this.submit}>NEXT</Button>
							)}
					</Form.Group>
				</Form>

			</Container>
		);
	}
}

export default withRouter(Emails);
