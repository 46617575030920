import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Redirect, withRouter } from "react-router-dom";
import Question from "./question/question";
import Solution from "./solution/solution";
import Header from "../../../commons/components/header/header";
import Lawsuit from "./lawsuit";
import Faq from "./faq";
import FinalStep from "./finalstep/finalstep";
import Analytics from "../../../commons/analytics";
import ClaimService from "../../../commons/services/claim-service";
import ClassActionModel from "../../../commons/model/class-action";
import "./cla-details.scss";

class ClaDetails extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = this.props;
		const companyName = params.claims.replace('claims', '');
		const claimService = new ClaimService();
		this.classActionDetails = claimService.details(companyName);
		this.classActionSummary = new ClassActionModel(this.classActionDetails);
		this.analytics = new Analytics();
		this.state = {
			currentStep: "1",
			currentQuestion: null,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.handleFinished = this.handleFinished.bind(this);
	}

	async componentDidMount() {
		if (this.state.currentQuestion === null) {
			const currentQuestion = await this.getQuestion("1");
			console.log('componentDidMount', currentQuestion);
			this.setState({ currentQuestion });
		}
	}

	async handleChange(response) {
		console.log('handleChange:', response, this.state.currentStep);
		let question = await this.getQuestion(this.state.currentStep);
		if (question.answers) {
			const currentAnswer = question.answers.find(currentAnswer => currentAnswer.heading === response);
			this.analytics.trackCTAinFathom(currentAnswer.fathomCode);
			question = await this.getQuestion(currentAnswer.nextStep);
			console.log('currentAnswer:', {
				currentStep: currentAnswer.nextStep,
				currentQuestion: question,
			});
			this.setState({
				currentStep: currentAnswer.nextStep,
				currentQuestion: question,
			});
		} else {
			question = await this.getQuestion(question.solution.nextStep);
			this.analytics.trackCTAinFathom(question.solution.fathomCode);
			this.setState({
				currentStep: question.solution.nextStep,
				currentQuestion: question,
			});
		}
	}

	async handleFinished(nextStep, fathomCode = null) {
		console.log('handleFinished:', nextStep, fathomCode);
		let question = await this.getQuestion(nextStep);
		if (fathomCode) {
			this.analytics.trackCTAinFathom(fathomCode);
		}
		this.setState({
			currentStep: "1",
			currentQuestion: question,
		});
	}

	async handleReset() {
		console.log('handleReset:');
		const question = await this.getQuestion("1");
		this.setState({
			currentStep: "1",
			currentQuestion: question,
		});
		this.analytics.trackCTAinFathom("ZOTYBGEZ");
	}

	async getQuestion(step) {
		return await this.classActionDetails && this.classActionDetails.questions && this.classActionDetails.questions.find(question => question.step === step);
	}

	render() {
		if (!this.classActionDetails)
			return <Redirect to="/classactions" />;
		else
			return (

				<Container className="container-cladetails-background" style={{overflow:"hidden"}} fluid>
					<Header textBlack={true} white={true}/>
					<div className="container-cladetails row">
						<div className="container-claimbg">
							<Lawsuit info={this.classActionSummary.details} claimStatusToShow={this.classActionSummary.claimStatusToShow} companyName={this.classActionSummary.companyName} totalPayout={this.classActionSummary.payoutInfo} />
						</div>
						{/* <div className="container-claimbg-mobile">
							<div className="container-claimbg-mobile-header">{this.classActionSummary.companyName}</div>
							<div className="container-claimbg-mobile-text">Deadline — {this.classActionSummary.claimStatusToShow} < br /> Payout  — {this.classActionSummary.payoutInfo}</div>
						</div> */}
						{/* <Container className="" */}
						{/* <Header /> */}
						<div className="container-survey">
							{
								(this.state.currentQuestion && this.state.currentQuestion.answers) &&
								<Question question={this.state.currentQuestion} handleChange={this.handleChange} handleReset={this.handleReset} />
							}
							{
								(this.state.currentQuestion && this.state.currentQuestion.solution) &&
								<Solution solution={this.state.currentQuestion} handleFinished={this.handleFinished} handleReset={this.handleReset} />
							}
							{
								(this.state.currentQuestion && this.state.currentQuestion.type === "finalstep") &&
								<FinalStep question={this.state.currentQuestion} handleFinished={this.handleFinished} handleReset={this.handleReset} />
							}
						</div>
						
					</div>
					<Faq faq={this.classActionDetails.faq} />
				</Container>
			);
	}
}

export default withRouter(ClaDetails);
