import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import "./finalstep.scss"
import Analytics from "../../../../commons/analytics";

const FinalStep = (props) => {

  const analytics = new Analytics();
	return (
			<Container className="container-question">
				<Row className="question-header justify-content-center">
						{props.question.header}
				</Row>
				<Row className="solution-text-bold justify-content-center">
						{props.question.subHeader}
				</Row>
				<Row className="solution-text justify-content-center">
						{props.question.description}
				</Row>
				<Row className="question-col-mode justify-content-center">
					<div className="finalstep-col">
						<p className="finalstep-col-header">Tell Everyone</p>
						<p className="finalstep-col-text">Your friends and family may be entitled to a big payout.</p>
						<div className="final-social-div">
							<FacebookShareButton className="final-black-rectangle" url={`https://datadividendproject.com/classactions`} quote="props.shareText">
								<div className="fb-image" onClick={e => analytics.trackCTAinFathom("JKWXIKUC")}></div>
							</FacebookShareButton>
							<TwitterShareButton className="final-black-rectangle" url={`https://datadividendproject.com/classactions`}>
								<div className="twitter-image" onClick={e => analytics.trackCTAinFathom("WP82W3AB")}></div>
							</TwitterShareButton>
						</div>
					</div>
					<div className="finalstep-col">
					<p className="finalstep-col-header">Stay Informed</p>
					<p className="finalstep-col-text">Join the movement and we’ll help you fight back against companies that misuse your data.</p>
					<a href="/signup?ref=yahooclaims" className="no-underline">
						<Button className="question-button">
							JOIN DDP 
						</Button>
					</a>
					</div>
				</Row>
			</Container>
	);
};

export default FinalStep;

