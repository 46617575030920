import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import Footer from "../../commons/components/footer/footer";
import Header from "../../commons/components/header/header";
import Landing from "./landing";
import News from "./news";
import Data from "./data";
import OrangeGraph from "./orangegraph";
import Movement from "./movement";
import World from "./world";
import Negotiate from "./negotiate";
import Circle from "./circle";
import Claims from "./claims";
import { Controller, Scene } from "react-scrollmagic";


class Home extends Component {

  render() {
    return (
      <Container className="home-container" style={{ overflow: "hidden" }} fluid>
        <Header />
        <Landing />
        <News />
        <Data />
        <OrangeGraph />
        <Movement />
        <World />
        <Negotiate />
        <Circle />
        <Claims />
        <Footer />
      </Container>
    );
  }
}

export default withRouter(Home);
