import React from "react";
import { Container, Button } from "react-bootstrap";

const Join = (props) => {
	return (
		<Container className="container-classactionjoin-background" fluid>
			<div className="container-classactionjoin">
				<div className="card-classactionjoin">
					<div className="classactionjoin-header">Stand up to Big Tech and take control of your data today. </div>
				</div>
				<a href="/signup?ref=classactionsjoin">
					<div className="classactionjoin-button">
						<Button href="/signup" className="button-classactionjoin">JOIN DDP NOW</Button>
					</div>
				</a>
			</div>
		</Container>
	);
};

export default Join;