export default {
	claimStatus: 'November 23, 2020',
	claimEndDate: '11/23/2020',
	claimColumnToShow: 'claimStatus',
	companyName: "Facebook",
	payoutInfo: "$650 Million",
	details: "If you lived in the State of Illinois for more than 183 days any time after June 7, 2011, and had a Facebook account, then you may be eligible for a cash payout.",
	desktopBackground: '/images/cla/facebook_header_v2.png',
	mobileBackground: '/images/cla/facebook_header_mobile_v2.png',
	redirectUrl: '/facebookclaims',
	shareText: "To all my friends in Illinois who are Facebook members - check out this 650 Million dollar class action settlement! Don't forget to make a claim and to join DDP!",
	questions: [
			{
				step: "1",
				header: "Did you have a Facebook account any time after June 7, 2011?",
				description: "",
				optionsAsRow: true,
				type: "question",
				answers: [{
					heading: "Yes",
					nextStep: "2",
					fathomCode: "9DRHOPOX",
				},
				{
					heading: "No",
					nextStep: "3",
					fathomCode: "CKGRPZZF",
				},
				{
					heading: "I'm Not sure",
					nextStep: "4",
					fathomCode: "BWHTHJIR",
					description: "go and read the Official FAQ's",
					url: " http://www.facebookbipaclassaction.com/FAQ.aspx",
					urlText: "FAQ's",
				}],
				CTAText: "Next",
				CTAClass: "Green"
			},
			{
				step: "2",
				header: "Did you live in the State of Illinois for more than 183 days (6 months) at any time after June 7, 2011?",
				description: "",
				optionsAsRow: true,
				type: "solution",
				answers: [{
					heading: "Yes",
					nextStep: "5",
					fathomCode: "HDJ9GHBL",
				},
				{
					heading: "No",
					nextStep: "3",
					fathomCode: "FOHE9UVR",
				},
				{
					heading: "I'm Not sure",
					nextStep: "4",
					fathomCode: "BWHTHJIR",
					description: "go and read the Official FAQ's, No. 5",
					url: " http://www.facebookbipaclassaction.com/FAQ.aspx",
					urlText: "FAQ's",
				}],
				CTAText: "Next",
				CTAClass: "Green"
			},
			{
				step: "3",
				header: "Sorry! You are not eligible.",
				description: "Here's what you should do next",
				optionsAsRow: false,
				type: "finalstep",
			},
			{
				step: "4",
				header: "It's not over yet!",
				description: "Here's what you should do next",
				optionsAsRow: false,
				type: "finalstep",
			},
			{
				step: "5",
				header: "Nice! You may be eligible if Facebook created/stored a face template for you.",
				subHeader: "",
				description: "",
				optionsAsRow: true,
				type: "solution",
				solution: {
					instructions: [{
						facebook: "true",
						stepvisible:"hidden",
						step: "none",
						header: "START CLAIM NOW",
						description: "Go and make a claim ",
						url: "https://kccsecure.com/facebookbipaclassaction/Claimant",
						urlText: "START CLAIM NOW",
					}],
					nextStep: "6",
					fathomCode: "Q0AF42SU",
				},
				CTAText: "Finished",
				CTAClass: "Green"
			},
			{
				step: "6",
				header: "Here's what you can do while your Facebook claim is being processed:",
				description: "",
				optionsAsRow: false,
				type: "finalstep",
			},
		],
	faq: {
		header: "Facebook Illinois Biometric Class Action Settlement - The Largest Data Privacy Class Action Settlement in History",
		description: null,
		details: null,
		questions: [
			{
				question: "What was this Facebook litigation about?",
				answer: `In 2010, Facebook introduced a Tagging Suggestions feature which when turned on may have used facial recognition technology to 
figure out whether a user’s friends were in photos uploaded by the user.

The technology scanned the photo and if it detected a face, it would extract the biometric identifiers that make the face unique,
such as the distance between the person’s eyes. It would then use that information and compare it against its database of user face
templates to see if there was a match and if so, it may suggest tagging the matched person in the photo. Facebook stored this face 
template data on servers in nine data centers.

Facebook users in Illinois filed a class action, alleging that Facebook had not obtained written releases from them and retained the data without
retention deletion schedules as required by the [Illinois Biometric Information Privacy Act](https://www.ilga.gov/legislation/ilcs/ilcs3.asp?ActID=3004&ChapterID=57).

The three class representatives ultimately filed a consolidated complaint in California district court in 2015, which led to the proposed settlement.`,
			},
			{
				question: "How much is the Proposed Settlement?",
				answer: `The settlement in this case is currently $650 million. Previously, it was $550 million, which was already the largest all-cash class action privacy settlement in history.
However, the presiding judge thought that the $550 million number was too small in light of Facebook’s $5 billion FTC fine in 2019 for 
deceiving users about how long Facebook kept their personal information.`,
			},
			{
				question: "Who does this apply to?",
				answer: `Illinois residents (who lived there for at least 6 months) for whom Facebook created and stored a face template between June 7, 2011 and August 19, 2020. 
If you are a class member, direct notice will be sent to you after the settlement has been approved.
					
If you think that you should be a class member, but have not received direct notice yet, then you can go [HERE](https://kccsecure.com/facebookbipaclassaction/Claimant) to file a claim, or you can read the [Official FAQ’s](http://www.facebookbipaclassaction.com/FAQ.aspx).`,
			},
			{
				question: "I am an Illinois resident and a Facebook user. Can I make a claim?",
				answer: `Facebook should have started notifying class members directly on Wednesday, September 23. Facebook’s records will be used to determine who is eligible. So, keep an eye out on your email and in your Facebook account when you log-in!
 
If you think that you should be a class member, but have not received direct notice from Facebook yet, then you can go [HERE](https://kccsecure.com/facebookbipaclassaction/Claimant) to file a claim, or you can read the [Official FAQ’s](http://www.facebookbipaclassaction.com/FAQ.aspx).`,
			},
			{
				question: "How much cash could I get?",
				answer: `The current estimate is that each eligible class member will receive between $200 and $400 each, depending on how many claims are filed.`,
			},
			{
				question: "What is the Illinois Biometric Information Privacy Act?",
				answer: `In 2008, Illinois enacted the Biometric Information Privacy Act (BIPA). Considered one of the most effective laws of its kind because it provides a private right of action for consumers, BIPA imposes certain obligations on the collection, retention, disclosure and destruction of biometric data.

It also requires a private entity to notify a person in writing and to secure a written release before acquiring his/her “biometric identifier”. 
According to BIPA, a “biometric identifier” includes a “scan of hand or face geometry.” 

The Illinois state legislature found that biometric data is “biologically unique” and unlike social security numbers, cannot be changed once it is stolen. The individual faces a higher risk of 
identity theft and has no recourse.

Given the potential harm at stake, BIPA doles out some stiff penalties: $1,000 for each negligent violation and up to $5,000 for each intentional or reckless violation.`,
			},
			{
				question: "I am not an Illinois resident. Why doesn’t my State have a similar law?",
				answer: `Good question! [Join DDP](https://www.datadividendproject.com/signup) and we will help you lobby your State and Federal legislators to get a similar law passed.`,
			},
			{
				question: "Still have more questions?",
				answer: `You can read the [Official FAQ’s](http://www.facebookbipaclassaction.com/FAQ.aspx).

You can also get free help by calling the Settlement Administrator at 1-844-799-2417 or by calling the lawyers appointed to represent Class Members in this case, Edelson PC (“Edelson”) of Chicago, Illinois 1-866-354-3015, Robbins Geller Rudman & Dowd LLP (“Robbins Geller”) of San Francisco, California 1-800-449-4900, and Labaton Sucharow LLP (“Labaton Sucharow”) of New York, New York 1-888-219-6877.`,
			}
		],
	},
};