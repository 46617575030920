export default class Analytics {
	
	constructor() {
		this.fathomGoals = [];
		this.fathomGoals['homepage-jointhemovement'] = '3JJ35IXK';
		this.fathomGoals['collectemails-addemails'] = '1D6USV4D';
		this.fathomGoals['collectemails-finished'] = 'XKUZUMWQ';
		this.fathomGoals['homepage-signuptoday'] = 'OXVIRLNJ';
		this.fathomGoals['homepage-readfullfaq'] = 'SNYCCQLI';
		this.fathomGoals['homepage-joinnow'] = 'PF7LIYQJ';
		this.fathomGoals['signature-modalOpen'] = 'VU39ZKVO';
		this.fathomGoals['signature-modalClose'] = 'SQKDZRCE';
		this.fathomGoals['signature-accept'] = 'Q79SQFID';
		this.fathomGoals['signUp-city-next'] = 'JYSUAEOU';
		this.fathomGoals['signUp-confirmation-confirm'] = 'IDHCTF9A';
		this.fathomGoals['signUp-confirmation-back'] = '3GVS75BN';
		this.fathomGoals['signUp-email-next'] = 'GYGWTPOI';
		this.fathomGoals['signUp-firstname-next'] = 'AS4ISCRO';
		this.fathomGoals['signUp-lastname-next'] = '5PNPS2YE';
		this.fathomGoals['signUp-phone-next'] = 'BPHBXBRF';
		this.fathomGoals['signUp-zipcode-next'] = 'MTUJUXHY';
		this.fathomGoals['signUp-street-next'] = 'PJOGYQPB';
		this.fathomGoals['signUp-dob-next'] = 'UYDSCGI6';
		this.fathomGoals['verifyphone-confirm'] = 'O3YO9XI6';
		this.fathomGoals['signIn-login-clicked'] = 'GGCSWTNI';
		this.fathomGoals['AccountDetails-save-clicked'] = 'TEVENAAO';
		this.fathomGoals['AccountDetails-email-resend'] = '8IFTRDPU';
		this.fathomGoals['UserEmails-addemail-clicked'] = 'BF9JIGJD';
		this.fathomGoals['UserEmails-deleteemail-clicked'] = 'FS5HTSAU';
	}

	trackEvent = async (args) => {
		if (window.sa_event) {
			window.sa_event(`${args.pageName}-${args.cta}-${args.actionName}`);
		}
		if (window.fathom && this.fathomGoals[`${args.pageName}-${args.cta}`]) {
			window.fathom.trackGoal(this.fathomGoals[`${args.pageName}-${args.cta}`], 0);;
		}
	}

	trackCTAinFathom = (fathomGoal) => {
		if (window.fathom) {
			window.fathom.trackGoal(fathomGoal, 0);;
		}
	}
}
