import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import QueryString from "query-string";
import CryptoJs from "crypto-js";
import DdpService from "../../commons/services/ddp-service";
import Footer from "../../commons/components/footer/footer";
import Header from "../../commons/components/header/header";

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: false,
      redirected: false,
      error: false
    };
  }

  async componentDidMount() {
    const values = QueryString.parse(this.props.location.search);
    const confirm = values.confirm;
    const decodedString = atob(confirm);
    const decodedValues = QueryString.parse(decodedString);
    const hash = await CryptoJs.SHA1(`${decodedValues.email}-${decodedValues.token}-${decodedValues.id}-${decodedValues.key}`);
    const token = hash.toString(CryptoJs.enc.Hex)
    if (token !== decodedValues.decode) {
      console.log("invalid url");
      this.setState({ completed: true });
    } else {
      try {
        const ddpService = new DdpService();
        const user = await ddpService.confirmSecondaryEmail({
          key: decodedValues.key,
          userId: decodedValues.id,
          email: decodedValues.email,
          token: decodedValues.token,
          decode: decodedValues.decode,
        });
        console.log("confirmemail user:", user);
        this.setState({ completed: true });
      } catch (err) {
        this.setState({ error: true });
        console.log(err);
      }
    }
  }

  render() {
    const { completed, error } = this.state;
    if (completed) {
      return (
        <Container fluid>
          <Header textBlack={true} />
          <Container className="confirm-container" style={{"height":"80vh"}}>
            Success! Your email has been verified. You can now close this page.
          </Container>
          <Footer />
        </Container>
      );
    } else if (error) {
      return (
        <Container fluid>
          <Header textBlack={true} />
          <Container className="confirm-container" style={{"height":"80vh"}}>
            Error! Your email could not be verified.
            </Container>
          <Footer />
        </Container>
      );
    }
    return (
      <Container fluid>
        <Header textBlack={true}  />
        <Container className="confirm-container" style={{"height":"80vh"}}>
          We are verifying your email.
        </Container>
        <Footer />
      </Container>
    );
  }
}

export default withRouter(ConfirmEmail);
