import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import Footer from "../../commons/components/footer/footer";
import Header from "../../commons/components/header/header";
import Movement from "./movement";
import VerifyEmails from "./verify-emails";
import Announcement from "./announcement";
import Spread from "./spread";
import Social from "./social";
import Timeline from "./timeline";

class Thankyou extends Component {

  render() {
    return (
      <React.Fragment>
        <Container className="container-thankyou-bg" fluid>
          <Header />
          <div className="container-thankyou row">
            <div className="thankyou-container-text">
              <div className="thankyou-header"> Welcome to the movement!</div>
              <div className="thankyou-text">We’re underway fighting for your data rights. While we negotiate with Big Tech and data brokers, <a href="https://blog.datadividendproject.com/" target="_blank">learn more</a> about what you can do now to take back control of your data.</div>
              <a href="/classactions"><div className="thankyou-seemore">see claims</div></a>
            </div>
            <div className="thankyou-globe"></div>
          </div>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(Thankyou);