import React from "react";
import { Navbar, Nav, Form, Button, Container } from "react-bootstrap";
import { slide as Menu } from 'react-burger-menu'
import blackDdpLogo from "../../../assets/images/ddp-logo-black.svg";
import whiteDdpLogo from "../../../assets/images/ddp-logo-white.svg";
import * as Utils from "../../utils";
import menuIcon from "../../../assets/MenuIcon.svg";
import mobileLogo from "../../../assets/images/ddp-logo.svg";

const Header = (props) => {
	function signout() {
		Utils.resetSessionStorage();
		window.location.href = "/signin";
	}

	const headerStyle = {
		backgroundImage: props.white ? `url(${whiteDdpLogo})` : `url(${blackDdpLogo})`
	}

	const textStyle = {
		color: props.textBlack ? '#000000' : '#ffffff'
	}

	return (
		<Container className="navbar-container" id="outer-container">
			<Navbar className="header-desktop">
				<Navbar.Brand href="/" className="nav-brand-header" style={headerStyle}>
				</Navbar.Brand>
				<Navbar.Collapse className="justify-content-end">
					<Nav>
							<Nav.Link className="header-item" style={textStyle} href="/aboutus">WHO WE ARE</Nav.Link>
						<Nav.Link className="header-item" style={textStyle} href="/classactions">CLAIMS TOOL</Nav.Link>
						<Nav.Link className="header-item" style={textStyle} href="https://blog.datadividendproject.com/">NEWS</Nav.Link>
						<React.Fragment>
							{!Utils.getAuthToken() && (
								<React.Fragment>
										<Nav.Link className="header-item" style={textStyle} href="/signin">SIGN IN</Nav.Link>
								</React.Fragment>
							)}
							{Utils.getAuthToken() && (
								<React.Fragment>
										<Nav.Link className="header-item" style={textStyle} href="/details">PROFILE</Nav.Link>
								</React.Fragment>
							)}
							{Utils.getAuthToken() && (
								<React.Fragment>
										<Nav.Link className="header-item" style={textStyle} onClick={signout}>SIGN OUT</Nav.Link>
								</React.Fragment>
							)}
						</React.Fragment>
					</Nav>
					{!Utils.getAuthToken() && (
						<Form inline>
								<Button className="header-button" href="/signup">JOIN NOW</Button>
						</Form>
					)}
				</Navbar.Collapse>
			</Navbar>
			<div className="header-mobile" >
				<a href="/" className="nav-brand-header-mobile" ><img className="nav-brand-header-mobile" src={mobileLogo}  alt="DDP New Logo" /></a>
				<Menu customBurgerIcon={ <img src= {menuIcon} /> }right className="header-mobile" width={ '100%' } outerContainerId={ "outer-container" } >
					<a id="home" className="menu-item" href="/aboutus">WHO WE ARE</a>
					<a id="about" className="menu-item" href="/classactions">CLAIMS TOOL</a>
					<a id="contact" className="menu-item" href="https://blog.datadividendproject.com/">NEWS</a>		
					{!Utils.getAuthToken() && (
						<a id="signin" className="menu-item" href="/signin">SIGN IN</a>
					)}
					{Utils.getAuthToken() && (
						<a id="signin" className="menu-item" href="/details" >PROFILE</a>
					)}
					{Utils.getAuthToken() && (
						<a id="signin" className="menu-item" href="/signin" onClick={signout}>SIGN OUT</a>
					)}
					{!Utils.getAuthToken() && (
						<Form inline>
								<Button className="header-button" href="/signup">JOIN NOW</Button>
						</Form>
					)}
				</Menu>
			</div>
		</Container>
	);
};

export default Header;
