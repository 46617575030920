export const CLASS_ACTION_LIST = [
	{
		claimStatus: 'Expired',
		claimEndDate: '11/23/2020',
		claimColumnToShow: 'claimStatus',
		group: "Past Claims",
		companyName: "Facebook",
		payoutInfo: "$650M",
		details: "If you lived in the State of Illinois for more than 183 days any time after June 7, 2011, and had a Facebook account, then you may be eligible for a cash payout.",
		desktopBackground: '/images/cla/facebook_header_v2.png',
		mobileBackground: '/images/cla/facebook_header_mobile_v2.png',
		redirectUrl: 'http://www.facebookbipaclassaction.com/',
	},
	{
		claimStatus: 'Expired',
		claimEndDate: '10/8/2020',
		claimColumnToShow: 'claimStatus',
		group: "Past Claims",
		companyName: "Google Plus",
		payoutInfo: "$7.5M",
		details: "If you had a Google Plus account between 2015 and 2019 then you may be eligible for a cash payout.",
		desktopBackground: '/images/cla/google_header.jpg',
		mobileBackground: '/images/cla/google_header_mobile.png',
		redirectUrl: 'https://googleplusdatalitigation.com/submit-claim.php',
	},
	{
		claimStatus: 'January 22, 2024',
		claimEndDate: '01/22/2024',
		companyName: "Equifax",
		claimColumnToShow: 'claimStatus',
		group: "Open Claims",
		payoutInfo: "$380M to $505M",
		details: "Check to see if you are eligible to make a claim to the Equifax data breach settlement.",
		desktopBackground: '/images/cla/equifax_header_mobile.png',
		mobileBackground: '/images/cla/equifax_header.png',
		redirectUrl: '/cladetails/equifaxclaims',
	},
	{
		claimStatus: 'EXPIRED',
		claimEndDate: '07/20/2020',
		claimColumnToShow: 'claimStatus',
		group: "Past Claims",
		companyName: "Yahoo",
		payoutInfo: "$117M",
		details: "If you had a Yahoo account between 2012 and 2016, then you may be eligible to submit a claim and get cold, hard cash for the failure to safeguard your data.",
		desktopBackground: '/images/cla/yahoo-header.png',
		mobileBackground: '/images/cla/yahoo-header-mobile.png',
		redirectUrl: 'https://blog.datadividendproject.com/data-breaches-a-growing-problem/',
	},
]