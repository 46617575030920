
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment';

export function resetSessionStorage() {
	window.sessionStorage.clear();
}

export function setSessionData(user, signIn = false) {
	window.sessionStorage.setItem("authToken", user.authToken);
	window.sessionStorage.setItem('userId', user.userId);
	window.sessionStorage.setItem("email", user.email);
	window.sessionStorage.setItem("phone", user.phone);
	window.sessionStorage.setItem("firstName", user.firstName);
	window.sessionStorage.setItem("lastName", user.lastName);
	window.sessionStorage.setItem("ddpSupported", user.ddpSupported);
	window.sessionStorage.setItem("signin", signIn);
}

export function getAuthToken(){
	return window.sessionStorage.getItem("authToken");
}

export function getUserId(){
	return window.sessionStorage.getItem("userId");
}

export function getPhoneNumber(){
	return window.sessionStorage.getItem("phone");
}

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGEX = /^[a-zA-Z][a-zA-Z '-]+$/;
export const STATE_REGEX = /^[a-zA-Z][a-zA-Z]$/;
export const ZIP_REGEX = /^\d{5}$/;

export function validateBirthDate(dob) {
	console.log('checkDate:', dob, moment(dob, "YYYY-MM-DD", true).isValid(), moment(dob).format("MM/DD/YYYY"));
	if (moment(dob, "YYYY-MM-DD", true).isValid() || moment(dob, "MM/DD/YYYY", true).isValid()) {
		console.log('checkDate: valid date', dob, moment().subtract(18, 'years'));
		if (moment(dob).isAfter(moment().subtract(18, 'y'))) {
			return { 
				error: 'You should be 18+ to signup',
				valid: false
			};
		} else if (moment(dob).isBefore(moment('1910-01-01'))) {
			return { 
				error: 'Valid date of birth is required.',
				valid: false
			};
		}
		return { 
			error: '',
			valid: true
		};
	} else {
		return { 
			error: 'Invalid Date of Birth. Please use this format: MM/DD/YYYY. Do not forget to add the “/” marks, or else the date will show as invalid',
			valid: false
		};
	}
}

export function validateUserData(user) {
	const errors = []
	if ((user.email && !EMAIL_REGEX.test(user.email)) || !user.email) {
		errors.push({ 
			error: 'Please enter valid enail.',
			field: 'email',
		});
	}
	if ((user.firstName && !NAME_REGEX.test(user.firstName)) || !user.firstName) {
		errors.push({ 
			error: 'Please enter valid first name.',
			field: 'firstName',
		});
	}
	if ((user.lastName && !NAME_REGEX.test(user.lastName)) || !user.lastName) {
		errors.push({ 
			error: 'Please enter valid last name.',
			field: 'lastName',
		});
	}
	const phoneNumber = parsePhoneNumberFromString(user.phone, 'US');
	console.log('phoneNumber', phoneNumber);
	if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
		errors.push({ 
			error: 'Please enter valid phone number.',
			field: 'phone',
		});
	}
	if (user.street.length < 2) {
		errors.push({ 
			error: 'Please enter valid street address.',
			field: 'street',
		});
	}
	if ((user.city && !NAME_REGEX.test(user.city)) || !user.city) {
		errors.push({ 
			error: 'Please enter valid city.',
			field: 'city',
		});
	}
	if ((user.state && !STATE_REGEX.test(user.state)) || !user.state) {
		errors.push({ 
			error: 'Please enter valid state.',
			field: 'state',
		});
	}
	if ((user.zipCode && !ZIP_REGEX.test(user.zipCode)) || !user.zipCode) {
		errors.push({ 
			error: 'Please enter valid zipcode.',
			field: 'zipcode',
		});
	}
	const dateCheck = validateBirthDate(user.yob);
	if (!dateCheck.valid) {
		errors.push({ 
			error: dateCheck.error,
			field: 'yob',
		});
	}	
	return errors;
}


		  