import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import Footer from "../../commons/components/footer/footer";
import Header from "../../commons/components/header/header";
import Whoweare from "./whoweare.jsx";
import Aboutustext from "./aboutustext.jsx";
import Aboutustextgrey from "./aboutustextgrey.jsx";
import Faq from "./faq.jsx";

class AboutUs extends Component {

	render() {
		return (
			<Container className="container-aboutus-main" style={{overflow:"hidden"}} fluid>
				<Header textBlack={true} />
				<Whoweare />
				<Aboutustext />
				<Aboutustextgrey />
				<Faq />
				<Footer />
			</Container>
		);
	}
}

export default withRouter(AboutUs);

