export default {
	claimStatus: 'January 22, 2024',
	claimEndDate: '01/22/2024',
	companyName: "Equifax",
	claimColumnToShow: 'claimStatus',
	payoutInfo: "$380M to $505M",
	details: "Check to see if you are eligible to make a claim to the Equifax data breach settlement.",
	desktopBackground: '/images/cla/equifax_header_mobile.png',
	mobileBackground: '/images/cla/equifax_header.png',
	redirectUrl: 'https://blog.datadividendproject.com/data-breaches-a-growing-problem/',
	shareText: "Don't forget to make a claim and to join DDP!",	
	questions: [
		{
			step: "1",
			header: "Was your personal data stolen during the EQUIFAX data breach?",
			description: `Check [HERE](https://eligibility.equifaxbreachsettlement.com/en/Eligibility)
if your personal information was impacted by the Equifax Data Breach.

You can also call the Settlement Administrator toll-free at 1-833-759-2982 to check on whether your personal information was taken.`,
			optionsAsRow: true,
			type: "question",
			answers: [{
				heading: "Yes",
				description: "You might be eligible to claim cash",
				nextStep: "2",
				fathomCode: "W3BQSSVO",
			},
			{
				heading: "No",
				description: "You aren’t eligible to make a claim at this time",
				nextStep: "5",
				fathomCode: "C0A9ICWF",
			}],
			CTAText: "Next",
			CTAClass: "Green"
		},
		{
			step: "2",
			header: "Nice, you may qualify",
			description: "Do you have **out-of-pocket losses (up to $20,000) during the time period between January 23, 2020 and January 22, 2024** that you believe resulted from the Equifax data breach?",
			optionsAsRow: true,
			type: "question",
			answers: [{
				heading: "Yes",
				description: "You can be reimbursed up to $20,000, but you must submit documents supporting your claim",
				nextStep: "4",
				fathomCode: "SLZTJ1MH",
			},
			{
				heading: "No",
				description: "You still may qualify to receive cash for any time you spent dealing with the data breach.",
				nextStep: "3",
				fathomCode: "Y3KLOE0K",
			},
			{
				heading: "Don't Know",
				description: "You still may qualify to receive cash for any time you spent dealing with the data breach.",
				nextStep: "3",
				fathomCode: "2MBRAEV6",
			}],
			CTAText: "Next",
			CTAClass: "Green"
		},
		{
			step: "3",
			header: "You May Still Qualify",
			description: "Did you spend time recovering from fraud, identity theft, or other misuse of your personal information fairly traceable to the Equifax hack **during the time period between January 23, 2020 and January 22, 2024**?",
			optionsAsRow: true,
			type: "question",
			answers: [{
				heading: "Yes",
				description: "You can get up to $25/hour for up to 10 hours without documentation, or up to 20 hours if you provide supporting documents.",
				nextStep: "4",
				fathomCode: "PPSI4JGA",
			},
			{
				heading: "No",
				description: "You’re not eligible for any payment right now, but continue to check back if circumstances change, as you can file a claim until 2024.",
				nextStep: "5",
				fathomCode: "DK2JLBYB",
			}
			],
			CTAText: "Next",
			CTAClass: "Green"
		},
		{
			step: "4",
			header: "File a claim now",
			subHeader: "You may qualify for a cash payout.",
			description: "To claim your payout, complete the following steps:",
			optionsAsRow: true,
			type: "solution",
			solution: {
				instructions: [{
					step: "1",
					header: "START CLAIM NOW",
					description: "Begin filling out your claim ",
					url: "https://www.equifaxbreachsettlement.com/file-a-claim",
					urlText: "here",
				}],
				nextStep: "6",
				fathomCode: "1OFOGWDL",
			},
			CTAText: "Finished",
			CTAClass: "Green"
		},
		{
			step: "5",
			header: "Sorry, you don't qualify",
			description: "Here's what you should do next",
			optionsAsRow: false,
			type: "finalstep",
		},
		{
			step: "6",
			header: "It's not over yet",
			description: "Here's what you should do next",
			optionsAsRow: false,
			type: "finalstep",
		},
	],
	faq: {
		header: "The facts",
		description: null,
		details: null,
		questions: [
			{
				question: "What is this class action settlement about?",
				answer: `In 2017, Equifax announced a criminal hack that impacted the personal information of **147 million people**. The stolen information included names, 
					Social Security numbers, birth dates, addresses, and in some instances driver’s license numbers, credit card numbers, or other personal information.

Numerous class actions were filed against Equifax.  A Georgia federal court approved a class action settlement to resolve class action lawsuits 
brought as a result of the data breach.

Equifax denied any wrongdoing and no finding of wrongdoing was made, but Equifax has agreed to pay at least **$380.5 million** into a Consumer Restitution Fund. 
If that runs out, Equifax will pay up to an **additional $125 million** to cover out-of-pocket losses of consumers.

The Georgia federal court approved the settlement in December 2019; however, that decision has been appealed by objectors to the settlement. **The settlement cannot be 
distributed until the appeals are resolved.** Currently, there is no timeline for resolution of those appeals.

**On January 22, 2020**, the **Initial Claims Period Deadline** passed. However, there is an **Extended Claims Period** that ends **on January 22, 2024**.

You should feel free to ask your lawyer to read the legalese [here](https://www.equifaxbreachsettlement.com/documents).`,
			},
			{
				question: "When do I have to make a claim by?",
				answer: `The Initial Claims Period expired on January 22, 2020. However, you may be eligible to make a claim during the Extended Claims Period. 
					The deadline for filing Extended Claims is **January 22, 2024**.`,
			},
			{
				question: "What’s this settlement about?",
				answer: `If you suffered out-of-pocket losses or spent time dealing with fraud, identity theft, or other misuse of your personal information **during the time period between
January 23, 2020 and January 22, 2024**, you may be eligible for reimbursement.

However, you must certify that you have not already been reimbursed from other sources and documentation is required for any time you spent in excess of 10 hours. If you claim less than 10 hours, then you do not need to submit documentation.`,
			},
			{
				question: "How much cash can I get out of it?",
				answer: `You may receive reimbursement for up to 10 hours of time spent dealing with fraud or identity theft or misuse or your personal information at $25 an hour without documentation—a total of $250.

You must provide a description of what you did or preventative measures you took and the time spent. You must also certify that your description is true. Valid claims will be reimbursed in 15-minute increments with a minimum reimbursement of one hour.

You may receive reimbursement for up to 20 hours of time spent (totaling $500) but you must provide documentation.

You may also be reimbursed for out-of-pocket losses up to $20,000 with the necessary documentation. Examples of relevant documents include: account statements with the unauthorized charges highlighted, 
police reports, IRS documents, FTC identity theft reports, letters refusing to refund you for fraudulent charges, notary fees, fax fees, postage costs, 
copying fees, phone charges, a detailed list of places traveled to with reason and mileage, and receipts, bills, and invoices from professionals such as lawyers and accountants.

However, these monetary amounts are not guaranteed. As long as there is money remaining in the Consumer Restitution Fund after payment of the claims from the Initial Claims Period, 
and the Extended Claims Period Claim is found to be valid, they will be paid on a first-come-first-serve basis.

Furthermore, payments can be adjusted based on the number of valid claims. Read the official [FAQs](https://www.equifaxbreachsettlement.com/faq) on the Equifax Data Breach Settlement website.`,
			},
			{
				question: "When will I receive the benefits from the settlement?",
				answer: `Payment can only be made from the Consumer Restitution Fund after the appeals are resolved and the settlement is effective. As of now, there is no estimated timeframe for resolution of the appeals.

DDP will update you on any new developments. You can also check the Equifax Data Breach Settlement website periodically for updates.`,
			},
			{
				question: "How much is the settlement in total?",
				answer: `Equifax will pay at least $380.5 million USD to the settlement’s “Consumer Restitution Fund.”  Another $125 million USD is earmarked for 
time and money spent dealing with the data breach if the initial pool gets used up.

However, those numbers include payment to class counsel, litigation costs, and the costs of settlement administration.`,
			},
			{
				question: "How will this be prevented in the future?",
				answer: `Equifax has agreed to a 5-year Business Practices Commitments related to information security, which will be assessed by an independent third party and is enforceable in court. 
					A description of Equifax’s Commitments is detailed in the Settlement Agreement, which is available on the Equifax data breach settlement website 
					[here](https://www.equifaxbreachsettlement.com/admin/services/connectedapps.cms.extensions/1.0.0.0/6fa032d1-c4c1-4544-8e44-672fdf6124e5_1033_Settlement_Agreement_ECF_739-2.pdf).`,
			},
			{
				question: "What if my friends and family don’t read English?",
				answer: `There is a Spanish version of the site [here](https://www.equifaxbreachsettlement.com/es).`,
			},
			{
				question: "Still have more questions?",
				answer: `You can read more about the Equifax settlement [here](https://www.equifaxbreachsettlement.com/faq).

Or You can email [support@datadividendproject.zendesk.com](mailto:support@datadividendproject.zendesk.com?subject=Questions%20re%20Equifax%20Settlement) with the 
subject line “Questions re Equifax Settlement”. Unless it is a question that requires the advice of a lawyer (which we cannot provide), we will do our best to respond within 72 hours.`,
			}
		],
	},
};