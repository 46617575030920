import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import "./thankyou.scss";

const YourState = () => {
	const ref = useRef(null);

	return (
		<Container className="container-yourstate" fluid ref={ref}>
			<div className="yourstate-title" >
				WE’RE COMING TO YOUR STATE SOON
			</div>
			<div className="yourstate-text" >
				We noticed you aren’t a California resident. For the first round, we will be focusing on California residents who fall under the California Consumer Privacy Act. In the coming months, we will look at other state legislation including those states with forthcoming legislation. We will notify you when that happens.  
			</div>
			<div className="yourstate-rectangle" />
					<img className="comingsoon-image" alt="" />
			<div className="yourstate-comingsoon" />
		</Container>
	);

}  

export default YourState;