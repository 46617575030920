import React, { Component } from "react";
import { Container} from "react-bootstrap";
import "./home.scss";

class News extends Component {

	render() {
		return (
			<Container className="container-news" fluid>
				<div className="news-background"></div>
			</Container>
		);
	}
}

export default News;