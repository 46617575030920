import React from "react";
import {  Nav } from "react-bootstrap";
import * as Utils from "../../utils";

const AccountNav = () => {
    function signout() {
        Utils.resetSessionStorage();
        window.location.href ="/signin";
    }

    return (
			<Nav
				 defaultActiveKey="/details"
				 bg="dark"
				 className="justify-content-center"
			>
			{
				Utils.getAuthToken()  && (
					<React.Fragment>
						<Nav.Item>
							<Nav.Link className="a-header-nav" href="/details">Account Profile</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link className="a-header-nav" eventKey="emails"  href="/emails">Emails</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link className="a-header-nav" eventKey="signout" onClick={signout}>Sign Out</Nav.Link>
						</Nav.Item>
					</React.Fragment>
				)
			}
			</Nav>
    );
};

export default AccountNav;
