import React, { Component } from "react";
import { Container, Button } from "react-bootstrap";
import "./classaction.scss";

class Landing extends Component {

	render() {
		return (
			<Container className="container-classaction-background" fluid>
				<div className="container-classaction">
					<div className="header-classaction">
						The DDP Data Rights Settlements Claim Tool
				</div>
				</div>
			</Container>
		)
	}
}

export default Landing;  