
import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import AccountService from "../../../commons/services/account-service";
import * as Utils from "../../../commons/utils";

export const TermsModal = (props) => {
	const [termsAccepted, setTermsAccepted] = useState(null);
	const [error, setError] = useState('');

	const handleCheck = (e) => {
		console.log('handleCheck', e.target.checked)
		setTermsAccepted(e.target.checked);
	}

	const saveAndContinue = async (e) => {
		if (!termsAccepted) {
			setError("Please review and accept the DDP Terms & Conditions and the Privacy Policy to continue.");
			return;
		} else {
			console.log('terms:', termsAccepted);
			const accountService = new AccountService();
			await accountService.acceptTerms(Utils.getUserId());
			props.onHide();
		}
	}

	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter" className="terms-modal-header">
					DDP Terms and Conditions
        </Modal.Title>
			</Modal.Header>
			<Modal.Body className="terms-modal-body">
				<Form onSubmit={saveAndContinue}>
					<Form.Group className="form-group-modal">
						<Form.Check
							className="form-check-green"
							required
							name="terms"
              value={termsAccepted}
						>
							<Form.Check.Input
								className="form-check-box-green"
								type="checkbox"
								isValid
								onChange={handleCheck}
							/>
							<Form.Check.Label className="form-check-black-label"> By checking this box, I affirm that I have read and agree to be bound by the DDP {' '}
							<a href="/privacy?tab=terms"  target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>{' '}
							and the {' '}<a href="/privacy?tab=privacy"  target="_blank" rel="noopener noreferrer">DDP Privacy Policy.</a></Form.Check.Label>
						</Form.Check>
					</Form.Group>
					<Form.Group className="form-group-modal-center">
						{error && (
							<p className="error-text">{error}</p>
						)}
						<Button
										className="continue-button-modal"
										onClick={saveAndContinue}
									>
										CONTINUE{" "}
									</Button>
					</Form.Group>
				</Form>
			</Modal.Body>
		</Modal>
	);
}


