import ClassActionModel from "../model/class-action";
import { CLASS_ACTION_LIST } from "../data/classactions/summary";
import { ClassActionFactory } from "../data/classactions/class-action-factory";

export default class ClaimService {
	constructor(){
		this._classActions = [];
		this.loadClassActions();
	}

	loadClassActions() {
		try {
			CLASS_ACTION_LIST.forEach(classAction => {
				this._classActions.push(new ClassActionModel(classAction));
			});
		} catch (err) {
			console.log(err);
		}
  }

	classActions() {
		return this._classActions;
	}

	details(classActionName) {
		return ClassActionFactory(classActionName);
	}
}
