import GhostContentAPI from '@tryghost/content-api'

export default class ContentService {
	async getPageContent(pageName) {
		try {
			const ghostParams = {
				url: process.env.REACT_API_GHOST_API_URL || "https://blog.datadividendproject.com",
				key: process.env.REACT_API_GHOST_API_KEY || "9ad7846a220c2ce6194d5e77ca",
				version: "v3"
			};
			console.log('params:', ghostParams);
			const api = new GhostContentAPI(ghostParams);
			const pageContent = await api.pages.read({slug: pageName});
			console.log('Retrived data from CMS:', pageName, pageContent);
			return pageContent;
		} catch (err) {
			console.log('Retrived data from CMS Failed:', pageName, err);
			return null;
		}
	}

}