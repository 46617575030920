import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Lottie from "lottie-react";
import globe from "../../assets/globe.json";
import "./home.scss";
import lottie from "lottie-web";




class World extends Component {

	componentDidMount() {
		const animDataMobile = {
			container: document.getElementById('world-mobile-image'),
			animationData: globe,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			name: "animGlobe",
		}
		const anim = lottie.loadAnimation(animDataMobile);
		// anim.goToAndStop();
	}

	render() {
		return (
			<Container className="container-world-bg" fluid>
				<div className="container-world">
					<div className="world-header">
						Take back ownership of your data
					</div>
					<div className="world-text-container">
						<div className="world-subheader">
							THE LEGISLATION
						</div>
						<div className="world-text">
							The California Consumer Privacy Act (CCPA) and the California Privacy Rights Act (CPRA) give consumers a set of data rights: the right to know what data is being collected about them, the right to opt out of the sale or sharing of that data, and the right to demand deletion of their data. These laws also hold corporations accountable when they violate your data rights.
						</div>
						<a href="/manifesto"><div className="world-learnmore">
							Learn More
						</div>
						</a>

					</div>
					<div className="world-mobile">
						<div className="world-mobile-image" id="world-mobile-image"></div>
					</div>
				</div>
			</Container>
		);
	}
}

export default World;