import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import DdpService from "../../commons/services/ddp-service";
import Analytics from "../../commons/analytics";
import * as Utils from "../../commons/utils";
// import "./verify.css";

class VerifyPhone extends Component {
	constructor(props) {
		super(props);
		const phone = Utils.getPhoneNumber();
		this.state = {
			phone: phone,
			confirmationCode: [],
			validated: false,
			completed: false,
			errorText: '',
			value1: "",
			value2: "",
			value3: "",
			value4: "",
			value5: "",
			value6: ""
		};
		this.analytics = new Analytics();
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (event) => {
		if (parseFloat(event.target.value) || event.target.value === "0") {
			const { confirmationCode } = this.state;
			confirmationCode[event.target.id] = event.target.value;
			console.log('position', event.target.getAttribute('valuepos'), confirmationCode.length, confirmationCode);
			this.setState({
				[event.target.id]: event.target.value,
				confirmationCode,
			});
			if (event.target.value.length === 1) {
				const nextBtnName = event.target.getAttribute('nextid');
				if (nextBtnName) {
					console.log(event.target.nextid, document.getElementById(nextBtnName))
					document.getElementById(nextBtnName).focus();
				}
			}
			console.log('new confirmation code:', confirmationCode);
			if (confirmationCode.length === 6) {
				this.setState({
					validated: true,
					errorText: '',
				});
			} else if (confirmationCode.length < 6) {
				this.setState({
					validated: false,
					errorText: 'Please enter confirmation code.',
				});
			}
			console.log('confirmationCode', confirmationCode);
		} else {
			this.setState({
				[event.target.id]: ''
			});
		}
	};

	saveAndContinue = async (e) => {
		try {
			if (this.state.confirmationCode && Object.entries(this.state.confirmationCode).length === 6) {
				window.sessionStorage.removeItem("authToken");
				let tempCode = '';
				Object.values(this.state.confirmationCode).forEach(code => tempCode += code);
				const ddpService = new DdpService();
				const user = await ddpService.confirmMobile({
					phone: this.state.phone,
					code: tempCode,
				});
				console.log("verifyMobile user:", user);
				if (user.error || !user.userId) {
					this.setState({
						validated: false,
						completed: false,
						errorText: 'SMS validation failed.',
					});
					e.preventDefault();
					return;
				}
				this.analytics.trackEvent({
					pageName: 'verifyphone',
					cta: 'confirm',
					actionName: 'clicked'
				});
				Utils.setSessionData(user);
				this.props.nextStep();
			} else {
				this.setState({
					validated: false,
					completed: false,
					errorText: 'Please enter confirmation code.',
				});
			}
		} catch (err) {
			this.setState({
				completed: false,
				validated: false,
				confirmationCode: [],
				errorText: 'Please enter valid confirmation code'
			});
		}
		e.preventDefault();
	};

	requestResend = async (e) => {
		try {

			if (this.state.phone) {
				const ddpService = new DdpService();
				window.sessionStorage.removeItem("authToken");
				const user = await ddpService.verifyMobile({
					phone: this.state.phone,
				});
				console.log('user;', user);
				this.setState({
					value1: "",
					value2: "",
					value3: "",
					value4: "",
					value5: "",
					value6: "",
					completed: false,
					validated: false,
					confirmationCode: [],
					errorText: 'SMS code resent.'
				})
			}
		} catch (err) {
			this.setState({
				completed: false,
				validated: false,
				confirmationCode: [],
				errorText: 'Request SMS code failed.'
			});
		}
		e.preventDefault();
	};

	render() {
		const { phone, value1, value2, value3, value4, value5, value6, confirmationCode } = this.state;

		const phoneNumber = parsePhoneNumberFromString(phone, 'US');
			
		return (
			<Container className="verifyphone-container" style={{overflow:"hidden"}} fluid>
				<Form className="verify-form" onSubmit={this.saveAndContinue}>
					<Form.Group className="verify-code-group">
						<Form.Control className="verify-number" plaintext readOnly defaultValue={phoneNumber && phoneNumber.formatNational()} />
						<Form.Group className="verifycode-input-row">
							<Form.Control
								className="verifycode-input"
								type="text"
								onChange={(e) => this.handleChange(e)}
								maxLength="1"
								max="9"
								value={value1}
								pattern="[0-9]"
								valuepos="0"
								id="value1"
								nextid="value2"
								required />
							<Form.Control
								className="verifycode-input"
								type="text"
								onChange={(e) => this.handleChange(e)}
								maxLength="1"
								pattern="[0-9]"
								valuepos="1"
								max="9"
								value={value2}
								id="value2"
								nextid="value3"
								required />
							<Form.Control
								className="verifycode-input"
								type="text"
								onChange={(e) => this.handleChange(e)}
								maxLength="1"
								max="9"
								value={value3}
								pattern="[0-9]"
								valuepos="2"
								id="value3"
								nextid="value4"
								required />
							<Form.Control
								className="verifycode-input"
								type="text"
								onChange={(e) => this.handleChange(e)}
								maxLength="1"
								value={value4}
								id="value4"
								pattern="[0-9]"
								valuepos="3"
								nextid="value5"
								max="9"
								required />
							<Form.Control
								className="verifycode-input"
								type="text"
								onChange={(e) => this.handleChange(e)}
								maxLength="1"
								value={value5}
								id="value5"
								pattern="[0-9]"
								valuepos="4"
								nextid="value6"
								max="9"
								required />
							<Form.Control
								className="verifycode-input"
								type="text"
								onChange={(e) => this.handleChange(e)}
								maxLength="1"
								value={value6}
								pattern="[0-9]"
								valuepos="5"
								id="value6"
								max="9"
								required />
						</Form.Group>
						{!this.state.validated && (
							<Form.Text className="verify-error-text">
								{this.state.errorText}
							</Form.Text>
						)}
					</Form.Group>
					<Form.Group>
						<Button
							className="resend-button"
							onClick={this.requestResend}
						>
							Resend
            </Button>
					</Form.Group>
					<Form.Group>
						<Button
							className="verify-button"
							onClick={this.saveAndContinue}
							disabled={!(Object.entries(confirmationCode).length === 6)}
						>
							NEXT
            </Button>
					</Form.Group>
				</Form>
			</Container>
		);
	}
}

export default VerifyPhone;
