import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import Footer from "../../commons/components/footer/footer";
import Header from "../../commons/components/header/header";
import Privacy from "./privacy";

class PrivacyContainer extends Component {

	render() {
		return (
			<Container fluid>
				<Header textBlack={true} />
				<Privacy />
				<Footer />
			</Container>
		);
	}
}

export default withRouter(PrivacyContainer);
