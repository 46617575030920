import React from "react";
import { Container, Row, Table, Button } from "react-bootstrap";
import "./solution.scss"

const Solution = (props) => {

	const saveAndContinue = (e) => {
		props.handleFinished(props.solution.solution.nextStep, props.solution.solution.fathomCode);
	};

	return (
		<Container className="container-question">
			<div className="question-reset" onClick={props.handleReset} />
			<Row className="question-header justify-content-center">
				{props.solution.header}
			</Row>
			<Row className="solution-text-bold justify-content-center">
				{props.solution.subHeader}
			</Row>
			<Row className="solution-text justify-content-center">
				{props.solution.description}
			</Row>
			<Row className="solution-row-mode justify-content-center">
				{
					props.solution.solution.instructions.map(instruction => (
						<Row
							key={Math.floor(Math.random() * 10000)}
							className="solution-col-full justify-content-center"
						>
							<Table className="solution-instruction-col" >
								<tbody>
									<tr className="solution-instruction-tr">
										<td className="solution-instruction-td solution-instruction-td-green" style={{ visibility: instruction.stepvisible }}>
											<div className="solution-instruction-green-box">{instruction.step}</div>
										</td>
										<td className="solution-instruction-td">
											{instruction.facebook ? (
												<div className="solution-instruction-header" style={{textAlign:'center', paddingRight:'70px'}}>
														<a className="solution-link" href={instruction.url} target="_blank" rel="noopener noreferrer" style={{display:'block'}}>{instruction.header}</a>
													</div>
											) : (
													<React.Fragment>
														<div className="solution-instruction-header">{instruction.header}</div>
														<div className="solution-instruction-desc">
															{instruction.description}
															{instruction.url &&
																<a className="solution-link" href={instruction.url} target="_blank" rel="noopener noreferrer">{instruction.urlText}</a>
															}
														</div>
													</React.Fragment>
												)}
										</td>
									</tr>
								</tbody>
							</Table>
						</Row>
					))
				}
			</Row>
			<Row className="solutionbutton-margin  justify-content-center">
				<Button className="question-button" type="submit" onClick={saveAndContinue}>
					Finished
						</Button>
			</Row>
		</Container>
	);
};

export default Solution;