import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Lottie from "lottie-react";
import orangeData from "../../assets/orangedata.json";
import "./home.scss";
import { gsap } from "gsap";
import lottie from "lottie-web";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Controller, Scene } from 'react-scrollmagic';
// import { Tween, Timeline } from 'react-gsap';
import { ScrollLottie } from "../../commons/ScrollLottie";

var orangeAnim;

class OrangeGraph extends Component {

	constructor(props) {
		super(props)

		this.state = {
			style: {}
		}
		this.scene = null;

		this.tl = null;

		gsap.registerPlugin(ScrollTrigger);
	}

	componentDidMount() {
		const height = document.getElementById('orange-animation').clientHeight;
		const width = document.getElementById('orange-animation').clientWidth;
		this.setState({
			style: {
				height: height,
				width: width,
			}
		});
		console.log(width, height);
		// const animData = {
		// 	container: document.getElementById('orange-animation'),
		// 	animationData: orangeData,
		// 	renderer: 'svg',
		// 	loop: false,
		// 	autoplay: false,
		// 	name: "orangeData",
		// }
		// orangeAnim = lottie.loadAnimation(animData);
		if (width >= 1024) {
			ScrollLottie({
				target: '#orange-animation',
				trigger: "#container-orange-bg",
				animationData: orangeData,
				duration: 4,
				speed: 'slow'
			})
		}
	}
	//   .fromTo(target, {y: 50}, {y: -50})
	// .from(target, {opacity: 0, duration: 0.2}, 0)
	// .to(target, {opacity: 0, duration: 0.2}, 0.8)

	render() {
		return (
			<Container className="container-orange-bg" id="container-orange-bg" fluid>
				<div className="container-orange">
					<div className="orange-header">
						You don’t know them, but they know you
						</div>
					<div className="orange-text">
						Thousands of companies are tracking and then selling personal information about our health, our children, our finances, our interests, our location and our purchases.
						</div>
					<div className="orange-animation-mobile"></div>
					<div className="orange-animation" id="orange-animation">
						{/* <Lottie
							animationData={orangeData}
							style={this.state.style}
							interactivity={interactivity}
						/> */}
					</div>
				</div>
			</Container>
		);
	}
}

export default OrangeGraph;