import React, { Component } from 'react';
import { Container, Button, Table, Spinner, Form } from 'react-bootstrap';
import { Redirect, withRouter } from "react-router-dom";
import Footer from "../../../commons/components/footer/footer";
import Header from "../../../commons/components/header/header";
import AccountNav from "../../../commons/components/header/account-nav";
import AccountService from "../../../commons/services/account-service";
import Analytics from "../../../commons/analytics";
import * as Utils from "../../../commons/utils";
import UserEmailModel from "../../../commons/model/user-email";
import "./user-email.scss";

class UserEmails extends Component {
	constructor(props) {
		super(props);
		const userEmails = new UserEmailModel({});
		this.state = {
			completed: false,
			error: null,
			valid: false,
			userEmails,
			newEmail: '',
			processing: false,
		}
		this.analytics = new Analytics();
	}

	refreshData = async () => {
		this.setState({
			userEmails: {},
			newEmail: '',
			processing: true,
		});
		const accountService = new AccountService();
		const userEmails = await accountService.getUserEmails();
		if (userEmails.error || userEmails.errors) {
			this.setState({ error: userEmails.error || userEmails.errors, processing: false, });
			return;
		}
		this.setState({
			userEmails,
			newEmail: '',
			processing: false,
		});
	}

	addEmail = async (e) => {
		try {
			if (this.state.newEmail) {
				this.setState({ processing: true });
				const accountService = new AccountService();
				const { userEmails } = this.state;
				userEmails.addEmail(this.state.newEmail);
				await accountService.addEmails(userEmails.getDataForUpdate());
				await this.refreshData();
				this.analytics.trackEvent({
					pageName: 'UserEmails',
					cta: 'addemail',
					actionName: 'clicked'
				});
			}
		} catch (err) {
			this.setState({
				error: 'Add new email failed.',
				processing: false,
			});
		}
		e.preventDefault();
	}

	deleteEmail = async (e) => {
		try {
			console.log('deleteEmail:', e.target, e.target.attributes['data-id'].value);
			if (e.target.attributes['data-id'].value) {
				const { userEmails } = this.state;
				userEmails.deleteEmail(e.target.attributes['data-id'].value);
				const accountService = new AccountService();
				await accountService.deleteEmails(userEmails.getDataForDelete());
				await this.refreshData();
				this.setState({
					error: 'Email deleted successfully.'
				});
				this.analytics.trackEvent({
					pageName: 'UserEmails',
					cta: 'deleteemail',
					actionName: 'clicked'
				});
			}
		} catch (err) {
			this.setState({
				error: 'Request to delete email failed.'
			});
		}
		e.preventDefault();
	}

	requestResend = async (e) => {
		try {
			console.log('request resend:', e.target, e.target.attributes['data-id'].value);
			if (e.target.attributes['data-id'].value) {
				const accountService = new AccountService();
				await accountService.resendEmailVerification(e.target.attributes['data-id'].value);
				this.setState({ error: 'Verification email sent.' });
			}
		} catch (err) {
			this.setState({
				error: 'Request to resend email verfication failed.'
			});
		}
		e.preventDefault();
	};


	async componentDidMount() {
		await this.refreshData();
	}

	handleNewEmailChange = (event) => {
		this.setState({ newEmail: event.target.value });
	};

	render() {
		if (!Utils.getAuthToken() || this.state.error === 'Invalid token.') {
			return (<Redirect to="/signin" />);
		}

		const {
			userEmails: {
				emails
			},
			newEmail,
			processing,
		} = this.state;
		return (
			<React.Fragment>
				<Container className="container-email-bg"  style={{overflow:"hidden"}} fluid>
					<Header textBlack={true}/>
					<br />
					<br />
					<br />
					<br />
					<br />
					<div className="container-email">
						<AccountNav />
						<div className="email-form-container">
							<div className="email-heading">Your Emails</div>
							<Table className="email-table" responsive>
								<thead>
									<tr>
										<th className="email-col-text-bold email-col-1">Email
									</th>
										<th className="email-col-text-bold email-col-2">Primary
									</th>
										<th className="email-col-text-bold email-col-2">Verified
									</th>
										<th className="email-col-text-bold email-col-2">{' '}
										</th>
									</tr>
								</thead>
								<tbody>
									{(emails && emails.length > 0) && emails.map(email => (
										<tr className="intro-row" key={`${email.email}-emailrow`}>
											<td className="email-col-1 col-text-maxwidth" key={`${email.email}-emailrcol1`}>
												<div className="email-col-text-div " key={`${email.email}-email`} id={email.email}>{email.email}</div>
											</td>
											<td className="email-col-2 col-text-maxwidth" key={`${email.email}-emailrcolPrimary`}>
												<div className="email-col-text-div " key={`${email.email}-primary`} id={`${email.email}-primary`}>{email.emailPrimary}</div>
											</td>
											<td className="email-col col-text-maxwidth" key={`${email.email}-emailrcol2`}>
												{
													email.emailVerified === 'Y' && (
														<div className="email-col-text-div " key={`${email.email}-verify`} id={`${email.email}-verfiy`}>Verified</div>
													)
												}
												{
													email.emailVerified === 'N' && (
														<Button
															className="resend-button"
															onClick={this.requestResend}
															key={`${email.email}-verify`}
															data-id={email.email}
															id={`${email.email}-resend`}
														>
															Resend
														</Button>
													)
												}
											</td>
											<td className="email-col col-text-maxwidth" key={`${email.email}-emailrcol3`}>
												{
													email.emailPrimary === 'N' && (
														<Button
															className="resend-button"
															onClick={this.deleteEmail}
															key={`${email.email}-delete`}
															data-id={email.email}
															id={`${email.email}-delete`}
														>
															Delete
														</Button>
													)
												}
											</td>
										</tr>
									))
									}
									<tr className="intro-row">
										<td className="email-col">
											<input
												type="text"
												className="email-input"
												onChange={(e) => this.handleNewEmailChange(e)}
												id="email"
												value={newEmail}
												autoFocus
												placeholder="Enter email"
											/>
										</td>
										<td className="email-col">
											{!processing &&
												<Button
													className="email-button"
													onClick={this.addEmail}
													id={`addEmail`}
													disabled={newEmail.length < 5}
												>
													Add
												</Button>
											}
											{processing &&
												<Spinner animation="grow" variant="success" />
											}
											{this.state.error && (
											<Form.Text className="verify-error-text">
												{this.state.error}
											</Form.Text>
										)}
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</div>
				</Container>
				<Footer />
			</React.Fragment>
		);
	}
}

export default withRouter(UserEmails);