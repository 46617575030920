import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Container, Button, Form, Spinner } from "react-bootstrap";
import SignaturePad from "react-signature-pad-wrapper";
import DdpService from "../../commons/services/ddp-service";
import StatusHeader from "../../commons/components/header/status-header";
import "./signup.scss";
import { LegalContractModal } from "../signature/legalcontractmodal";
import Analytics from "../../commons/analytics";

class Signature extends Component {
	constructor(props) {
		super(props);
		const phone = window.sessionStorage.getItem("phone");
		const firstName = window.sessionStorage.getItem("firstName");
		const lastName = window.sessionStorage.getItem("lastName");
		const userId = window.sessionStorage.getItem("userid");
		this.state = {
			userId,
			signature: null,
			name: null,
			validated: false,
			phone,
			firstName,
			lastName,
			marketingEmails: false,
			showModal: false,
			errorText: '',
			processing: false,
		};
		this.analytics = new Analytics();
		this.handleChange = this.handleChange.bind(this);
		this.signaturePad = React.createRef();
		this.clearSignature = this.clearSignature.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
		this.saveAndContinue = this.saveAndContinue.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
	}

	componentDidMount() {
		this.signaturePad.clear();
		this.signaturePad.isEmpty();
		this.signaturePad.penColor = "rgb(0, 0, 0)";
	}

	handleModalOpen() {
		this.analytics.trackEvent({
			pageName: 'signature',
			cta: 'modalOpen',
			actionName: 'clicked'
		});
		this.setState({ showModal: true });
	}

	handleModalClose() {
		this.analytics.trackEvent({
			pageName: 'signature',
			cta: 'modalClose',
			actionName: 'clicked'
		});
		this.setState({ showModal: false });
	}

	handleChange = (event) => {
		if (event.target.value && event.target.value.length > 0) {
			this.setState({
				validated: true,
				name: event.target.value,
			});
		}
	};

	handleCheck = (event) => {
		this.setState({
			marketingEmails: event.target.checked,
		});
	};

	clearSignature = (event) => {
		this.signaturePad.clear();
	};

	saveAndContinue = async (e) => {
		try {
			if (this.signaturePad.isEmpty()) {
				this.setState({
					validated: false,
					errorText: 'Please sign to complete',
				});
				return;
			}
			if (!this.state.name) {
				console.log('name:', `${this.state.firstName} ${this.state.lastName}`, this.state.name);
				this.setState({
					validated: false,
					errorText: 'Please enter your FirstName LastName',
				});
				return;
			}
			if (this.state.name && !this.signaturePad.isEmpty()) {
				this.setState({ processing: true });
				const signatureImage = this.signaturePad.toDataURL();
				const ddpService = new DdpService();
				const user = await ddpService.updateSignature({
					phone: this.state.phone,
					signatureImage: signatureImage,
					marketingEmails: this.state.marketingEmails
				});
				if (user.error || user.errors) {
					throw new Error(user.error || user.errors);
				}
				this.analytics.trackEvent({
					pageName: 'signature',
					cta: 'accept',
					actionName: 'clicked'
				});
				console.log("Signature for user updated:", user);
				this.setState({ errorText: '', validated: true, processing: false, });
				this.props.nextStep();
			} else {
				this.setState({ validated: false, processing: false });
			}

		} catch (err) {
			console.log(err);
			this.setState({ validated: false, processing: false, });
		}
		e.preventDefault();
	};


	render() {
		const { processing } = this.state;

		return (
			<Container className="container-signature" fluid>
				<Form className="signature-form" onSubmit={this.saveAndContinue}>
					<Form.Group>
						<Form.Text className="signature-heading">
							To build the movement and negotiate collectively on your behalf, we will need some time to act as your authorized agent. So while you can revoke at any time, we hope that you decide to keep DDP as your authorized agent at least until December 2021.
            </Form.Text>
					</Form.Group>
					<Form.Group>
						<Form.Text className="form-subheading">
							<Button className="resend-button resend-button-signature" onClick={this.handleModalOpen}>View legal agreement</Button>
						</Form.Text>
					</Form.Group>
					<Form.Group className="white-background">
						<Form.Group className="white-background">
							<Form.Text className="signature-subheading">
								Enter your full name to e-sign
              </Form.Text>
						</Form.Group>
						<Form.Group className="white-background">
							<Form.Control
								className="field-input-signature"
								type="text"
								placeholder="type your name"
								onChange={(e) => this.handleChange(e)}
								maxLength="150"
								required
								autoFocus
							/>
						</Form.Group>
						<Form.Group className="white-background">
							<Form.Text className="signature-subheading" >
								Draw your signature to make it extra official
              </Form.Text>
						</Form.Group>
						<Form.Group
							className="signature-pad"
						>
							<Form.Group
								className="white-background"
							>
								<Form.Text
									onClick={this.clearSignature}
									className="clearsignature-overlay clearsignature-button">
									Clear
                </Form.Text>
							</Form.Group>
							<SignaturePad height={100}
								ref={(ref) => (this.signaturePad = ref)}
							/>
						</Form.Group>
						{!this.state.validated && (
							<Form.Group>
								<Form.Text className="text-invalid">
									{this.state.errorText}
								</Form.Text>
							</Form.Group>
						)}
						<Form.Group>
							<Form.Check
								className="form-check-green"
								required
								name="terms"
								value={this.state.checkAccepted}
							>
								<Form.Check.Input
									onClick={this.handleCheck} className="form-check-box-green" type="checkbox" isValid />
								<Form.Check.Label className="form-check-black-label">Check here to receive email updates from The Data Dividend Project about our progress.</Form.Check.Label>
							</Form.Check>
							{!processing &&
								<Button
									className="button-signature"
									onClick={this.saveAndContinue}
								>
									I ACCEPT
                </Button>
							}
							{processing &&
								<Spinner animation="grow" variant="success" />
							}
						</Form.Group>
					</Form.Group>
				</Form>
				<LegalContractModal
					show={this.state.showModal}
					onHide={this.handleModalClose}
				/>
			</Container>
		);
	}
}

export default withRouter(Signature);
