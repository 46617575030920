import React from "react";
import { Container, Nav, Row, Col } from "react-bootstrap";
import "./footer.scss";

const Footer = () => {

    return (
        <Container className="container-footer-bg" fluid>
            <div className="container-footer">
                <Nav>
                    <ul style={{ marginLeft: "40px" }}>
                        <div className="nav-brand"></div>
                        <div className="copyright">© 2021 The Data Dividend Project. 1999 Avenue of the Stars, Suite 1100 Los Angeles, CA 90067</div>
                    </ul>
                    <div className="footer-content row">
                        <div className="footer-col">
                            <ul>
                                <div className=" social-mobile row-footer">
                                    <a href="http://facebook.com/ddpforall" target="_blank" rel="noopener noreferrer"><div className="facebook-footer"></div></a>
                                    <a href="http://instagram.com/ddpforall" target="_blank" rel="noopener noreferrer"><div className="instagram-footer"></div></a>
                                    <a href="http://twitter.com/ddpforall" target="_blank" rel="noopener noreferrer"><div className="twitter-footer"></div></a>
                                </div>
                            </ul>
                        </div>
                        <div className="footer-col-mobile-wrapper">
                            <div className="footer-col">
                                <ul>
                                    <Nav.Item className="row-footer">
                                        <Nav.Link className="a-footer" href="/aboutus">WHO WE ARE</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="row-footer">
                                        <Nav.Link className="a-footer" href="https://blog.datadividendproject.com" target="_blank" rel="noopener noreferrer">NEWS AND UPDATES</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="row-footer">
                                        <Nav.Link className="a-footer" href="/classactions">ACTIVE DATA RIGHTS CLAIMS</Nav.Link>
                                    </Nav.Item>
                                </ul>
                            </div>
                            <div className="footer-col">
                                <ul>
                                    <Nav.Item className="row-footer">
                                        <Nav.Link className="a-footer" href="/signup">JOIN DDP NOW</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="row-footer">
                                        <Nav.Link className="a-footer" href="/manifesto">WHAT WE BELIEVE</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="row-footer">
                                        <Nav.Link className="a-footer" href="/privacy">PRIVACY POLICY/ TERMS AND CONDITIONS</Nav.Link>
                                    </Nav.Item>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Nav>
            </div>
        </Container >
    );
};

export default Footer;
