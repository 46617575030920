import moment from 'moment';

export default class ClassActionModel {
	constructor(data) {
		if (data) {
		this._claimStatus = data.claimStatus || '';
		this._claimEndDate = data.claimEndDate || '';
		this._companyName = data.companyName || '';
		this._details = data.details || '';
		this._claimColumnToShow = data.claimColumnToShow || '';
		this._payoutInfo = data.payoutInfo || '';
		this._desktopBackground = data.desktopBackground || '';
		this._mobileBackground = data.mobileBackground || '';
		this._redirectUrl = data.redirectUrl || '';
		this._group = data.group || '';
		this._error = data.error || '';
		}
	}

	get claimStatus() {
		return this._claimStatus;
	}

	get claimEndDate() {
		return this._claimEndDate;
	}

	get companyName() {
		return this._companyName;
	}

	get details() {
		return this._details;
	}

	get claimStatusToShow() {
		let daysLeft;
		if (this._claimEndDate) {
			daysLeft = moment(this.claimEndDate, "MM/DD/YYYY").diff(moment(), 'days') + 1;
		}
		return this._claimColumnToShow === 'claimStatus' ? this._claimStatus : daysLeft;
	}

	get desktopBackground() {
		return this._desktopBackground;
	}

	get mobileBackground() {
		return this._mobileBackground;
	}

	get payoutInfo() {
		return this._payoutInfo;
	}

	get redirectUrl() {
		return this._redirectUrl;
	}

	get group() {
		return this._group;
	}

	get error() {
		return this._error;
	}
}