import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import Footer from "../../commons/components/footer/footer";
import Header from "../../commons/components/header/header";
import Movement from "./movement";
import VerifyEmails from "./verify-emails";
import YourState from "./yourstate";
import Spread from "./spread";
import Social from "./social";

class ComingSoon extends Component {

  render() {
    return (
      <Container fluid>
        <Header />
        <Movement />
        <VerifyEmails />
        <YourState />
        <Spread />
        <Social />
        <Footer />
      </Container>
    );
  }
}

export default withRouter(ComingSoon);