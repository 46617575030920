import React, { useState, useEffect } from "react";
import { Container, Card, Accordion } from "react-bootstrap";
import ContentService from "../../commons/services/content-service";

import "./aboutus.scss";
const Faq = (props) => {
	const [pageContent, setPageContent] = useState(null);
	const [faqContent, setFaqContent] = useState([]);
	useEffect(() => {
		async function fetchData() {
			const contentService = new ContentService();
			const manifestoData = await contentService.getPageContent('faq');
			const manifestoQuestions = manifestoData.html.split("<p>#questions</p>");
			const faqContent = [];

			manifestoQuestions.forEach(questions => {
				const faq = questions.split("<p>#answer</p>");
				faq.question = {__html: faq[0]};
				faq.answer = {__html: faq[1]};
				faqContent.push(faq)
			})
			setPageContent('manifestoData.html:', manifestoQuestions);
			console.log('manifestoQuestions', faqContent);
			setFaqContent(faqContent);
		}
		fetchData();
	}, []); // Or [] if effect doesn't need props or state

	return (
		<Container className="clafaq-container" fluid>
			<div className="cla-faq-heading">FAQ</div>
			<Accordion className="accordion accordion-flush">
				{
					faqContent.map((faq, index) => (
						<React.Fragment>
							<Card className="card-container">
								<Card.Header className="card-title">
									{/* <div className="accordion-icon" id={index} style={eventKey}></div> */}
									<Accordion.Toggle as={Card.Header} variant="link" eventKey={faq.question}>
										<div className="faq-card-text" dangerouslySetInnerHTML={faq.question} />
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey={faq.question}>
									<Card.Body className="faq-card-text">
											<div className="faq-card-text" dangerouslySetInnerHTML={faq.answer} />
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<br />
						</React.Fragment>
					))
				}
			</Accordion>
			<br />
		</Container>
	)
}

export default Faq;